import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET_SUCCESS } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";
import DashboardTiles from "./DashboardTiles";
import { BiCategory, BiCategoryAlt, BiPackage } from "react-icons/bi";
import { TbBusinessplan } from "react-icons/tb";
import { LuPackageCheck } from "react-icons/lu";
import {
  MdOutlineDiscount,
  MdDiscount,
  MdOutlinePayments,
  MdPayments,
} from "react-icons/md";
import { HiOutlineReceiptRefund, HiReceiptRefund } from "react-icons/hi";
import { FaRegMoneyBillAlt, FaMoneyBill } from "react-icons/fa";
import {
  getDashboardCartData,
  getDashboardCount,
} from "../../store/AsyncMethods/DashboardMethod";
import DashboardBarChart from "./DashboardBarChart";
import { BiPurchaseTag } from "react-icons/bi";
import { useTranslation } from "react-i18next";

export default function DashboardMain() {
  const { success, user } = useSelector((state) => state.AuthReducer);
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  const currentDate = new Date();
  const { dashboardCount, chartData } = useSelector(
    (state) => state.DashboardReducer,
  );
  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState(null);
  const [todaySaleData, setTodaySaleData] = useState(null);
  const [currentMonthSaleData, setCurrentMonthSaleData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (user) {
      dispatch(getDashboardCount());
      dispatch(getDashboardCartData());
    }
  }, []);

  function getMonthTitle(monthIndex) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  }

  useEffect(() => {
    if (dashboardCount) {
      const currentDate = new Date();
      const currentMonthIndex = currentDate.getMonth();
      const currentMonthTitle = getMonthTitle(currentMonthIndex);
      setDashboardData([
        {
          amount: dashboardCount.total_categories || 0,
          title: t("total_categories"),
          icon: <BiCategory />,
        },
        {
          amount: dashboardCount.total_subcategories || 0,
          title: t("total_sub_categories"),
          icon: <BiCategoryAlt />,
        },
        {
          amount: dashboardCount.total_products || 0,
          title: t("total_products"),
          icon: <BiPackage />,
        },
        {
          amount: dashboardCount.total_active_products || 0,
          title: t("total_active_products"),
          icon: <LuPackageCheck />,
        },
        {
          amount: dashboardCount.total_purchase || 0,
          title: t("total_purchase_amount"),
          icon: <BiPurchaseTag />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: dashboardCount.total_sales || 0,
          title: t("total_sales"),
          icon: <TbBusinessplan />,
        },
      ]);

      setTodaySaleData([
        {
          amount: dashboardCount.current_date_invoice || 0,
          title: t("today_invoice_amount"),
          icon: <FaRegMoneyBillAlt />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: Math.abs(dashboardCount.current_date_refunds) || 0,
          title: t("today_refunds_amount"),
          icon: <HiOutlineReceiptRefund />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: dashboardCount.current_date_sales || 0,
          title: t("today_sales_amount"),
          icon: <MdOutlinePayments />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: dashboardCount.current_date_discount || 0,
          title: t("today_discounts_amount"),
          icon: <MdOutlineDiscount />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
      ]);

      setCurrentMonthSaleData([
        {
          amount: dashboardCount.current_month_invoice || 0,
          title: `${currentMonthTitle} ${t("invoice_amount")}`,
          icon: <FaMoneyBill />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: Math.abs(dashboardCount.current_month_refunds) || 0,
          title: `${currentMonthTitle} ${t("refunds_amount")}`,
          icon: <HiReceiptRefund />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: dashboardCount.current_month_sales || 0,
          title: `${currentMonthTitle} ${t("sales_amount")}`,
          icon: <MdPayments />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
        {
          amount: dashboardCount.current_month_discount || 0,
          title: `${currentMonthTitle} ${t("discounts_amount")}`,
          icon: <MdDiscount />,
          prefix: `${enableCurrency?.unit || ""} `,
        },
      ]);
    }
  }, [dashboardCount]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch({ type: RESET_SUCCESS });
    }
  }, [success]);

  return (
    dashboardData &&
    chartData && (
      <div className="px-4">
        <div>
          <div className="my-3 font-semibold text-black/50 text-xl ml-1">
            {t("records")}
          </div>

          <div>{dashboardData && <DashboardTiles data={dashboardData} />}</div>
        </div>
        <div className="mt-8">
          <div className="my-3 font-semibold text-black/50 text-xl ml-1">
            {t("today_sales")} (
            {currentDate.toLocaleDateString("en-UK", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
            )
          </div>
          <div>{todaySaleData && <DashboardTiles data={todaySaleData} />}</div>
        </div>
        <div className="mt-8">
          <div className="my-3 font-semibold text-black/50 text-xl ml-1">
            {t("this_month_sales")} ({getMonthTitle(currentDate.getMonth())})
          </div>
          <div>
            {currentMonthSaleData && (
              <DashboardTiles data={currentMonthSaleData} />
            )}
          </div>
        </div>
        <div className="mt-8">
          <div className="my-3 font-semibold text-black/50 text-xl ml-1">
            {t("yearly_sales_record")}
          </div>
          <div className=" card shadow-md p-4">
            {chartData && chartData.length > 0 ? (
              <DashboardBarChart data={chartData} />
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}
