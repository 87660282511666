import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import MultiLanguageNames from "../../components/OtherComponents/MultiLanguageNames";
import useScanDetection from "use-scan-detection-react18";

export default function AddProductForm({
  formik,
  filterSubCategories,
  specificProduct,
}) {
  const { categories } = useSelector((state) => state.CategoryReducer);
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  const { productId } = useParams();
  const { t } = useTranslation();
  const [showMultiLangModal, setShowMultiLangModal] = useState(false);
  const [scanProductBarcode, setScanProductBarcode] = useState("");

  useScanDetection({
    onComplete: (code) => {
      setScanProductBarcode(code);
      handleScanProductWithBarcode();
    },
    minLength: 13, // EAN13
  });
  const statusOptions = [
    { name: t("active"), value: true },
    { name: t("deactive"), value: false },
  ];

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("receipt-component"),
  });
  const handleScanProductWithBarcode = () => {
    if (scanProductBarcode.length > 0) {
      formik.setFieldValue("articleName", scanProductBarcode || "");
      setScanProductBarcode("");
    }
  };

  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {productId ? t("edit_product") : t("add_product")}
        </span>
      </Divider>
      <div className="px-4 pb-12  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="w-full flex">
          <span className="p-input-icon-left w-full">
            <i className="pi pi-search" />
            <InputText
              placeholder="Scan using barcode"
              className="rounded-r-none pl-12 w-full"
              value={scanProductBarcode}
              onChange={(e) => setScanProductBarcode(e.target.value)}
              onKeyUpCapture={(e) => {
                e.preventDefault();
                if (e.key === "Enter") {
                  handleScanProductWithBarcode();
                }
              }}
            />
          </span>
          <Button
            icon="pi pi-arrow-right"
            className="rounded-l-none p-black-btn"
            type="button"
            onClick={handleScanProductWithBarcode}
          />
        </div>
      </div>
      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="">
              {t("product_title")}
            </label>
            <span className="flex gap-2 w-full">
              <InputText
                id="title"
                name="title"
                className="w-full text-lg p-primary-input"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              <Button
                type="button"
                icon="fi fi-gb"
                size="large"
                text
                rounded
                className="  scale-100 !mr-auto"
                onClick={() => {
                  setShowMultiLangModal(true);
                }}
              />
            </span>
          </div>
          {formik.touched?.title && formik.errors?.title && (
            <div className="p-error">{formik.errors?.title}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="articleName" className="">
              {t("article_number")}
            </label>
            <span className=" w-full">
              <InputText
                id="articleName"
                name="articleName"
                className="w-full text-lg p-primary-input"
                value={formik.values.articleName}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.articleName && formik.errors?.articleName && (
            <div className="p-error">{formik.errors?.articleName}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryId" className="">
              {t("category")}
            </label>
            <Dropdown
              id="categoryId"
              name="categoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.categoryId}
              onChange={formik.handleChange}
              options={categories}
              optionLabel="name"
              optionValue="id"
              placeholder={t("select_category")}
              filter
              pt={{
                root: { className: "w-full md:w-full p-primary-input" },
                input: { className: "w-full p-primary-input" },
                filterInput: { className: "px-4 mr-0" },
                filterIcon: {
                  className:
                    "right-2 rtl:right-auto rtl:left-4 dark:text-white",
                },
              }}
            />
          </div>
          {formik.touched?.categoryId && formik.errors?.categoryId && (
            <div className="p-error">{formik.errors?.categoryId}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="subCategoryId" className="">
              {t("sub_category")}
            </label>
            <Dropdown
              id="subCategoryId"
              name="subCategoryId"
              placeholder={t("select_sub_category")}
              className="!w-full text-lg p-primary-input"
              value={formik.values.subCategoryId}
              onChange={formik.handleChange}
              options={filterSubCategories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full md:w-full p-primary-input" },
                input: { className: "w-full p-primary-input" },
                filterInput: { className: "px-4 mr-0" },
                filterIcon: {
                  className:
                    "right-2 rtl:right-auto rtl:left-4 dark:text-white",
                },
              }}
            />
          </div>
          {formik.touched?.subCategoryId && formik.errors?.subCategoryId && (
            <div className="p-error">{formik.errors?.subCategoryId}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="purchasePrice" className="">
              {t("purchase_price")}
            </label>
            <span className=" w-full">
              <InputNumber
                id="purchasePrice"
                name="purchasePrice"
                className="w-full text-lg p-primary-input"
                value={formik.values.purchasePrice}
                onValueChange={formik.handleChange}
                prefix={`${enableCurrency?.unit || ""} `}
                disabled={!formik.values.samePurchasePrice}
                placeholder="Enter Purchase Price"
              />
            </span>
          </div>
          {formik.touched?.purchasePrice && formik.errors?.purchasePrice && (
            <div className="p-error">{formik.errors?.purchasePrice}</div>
          )}
          {/* <div className="mt-2">
            <Checkbox
              inputId="samePurchasePrice"
              name="samePurchasePrice"
              id="samePurchasePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePurchasePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePurchasePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div> */}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="price" className="">
              {t("sale_price")}
            </label>
            <span className=" w-full">
              <InputNumber
                id="price"
                name="price"
                className="w-full text-lg p-primary-input"
                value={formik.values.price}
                onValueChange={formik.handleChange}
                prefix={`${enableCurrency?.unit || ""} `}
                disabled={!formik.values.samePrice}
                placeholder="Enter Sale Price"
              />
            </span>
          </div>
          {formik.touched?.price && formik.errors?.price && (
            <div className="p-error">{formik.errors?.price}</div>
          )}
          {/* <div className="mt-2">
            <Checkbox
              inputId="samePrice"
              name="samePrice"
              id="samePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div> */}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="discount" className="">
              {t("discount")}
            </label>
            <span className=" w-full">
              <InputNumber
                id="discount"
                name="discount"
                className="w-full text-lg p-primary-input"
                value={formik.values.discount}
                onValueChange={formik.handleChange}
                prefix={
                  formik.values.isDiscountPercentage
                    ? ""
                    : `${enableCurrency?.unit || ""} `
                }
              />
            </span>
          </div>
          {formik.touched?.discount && formik.errors?.discount && (
            <div className="p-error">{formik.errors?.discount}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="isDiscountPercentage"
              name="isDiscountPercentage"
              id="isDiscountPercentage"
              onChange={formik.handleChange}
              checked={formik.values.isDiscountPercentage}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="isDiscountPercentage" className="mx-2">
              {t("discount_in_percentage(%)")}
            </label>
          </div>
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="quantity" className="">
              {t("quantity")}
            </label>
            <span className=" w-full">
              <InputNumber
                id="quantity"
                name="quantity"
                className="w-full text-lg p-primary-input"
                value={formik.values.quantity}
                onValueChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.quantity && formik.errors?.quantity && (
            <div className="p-error">{formik.errors?.quantity}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="status" className="">
              {t("status")}
            </label>
            <SelectButton
              name="status"
              id="status"
              options={statusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center rtl:rounded-none"
                    : "w-full text-lg text-center rtl:rounded-none",
                }),
              }}
              value={formik.values.status}
              onChange={formik.handleChange}
            />
            {formik.touched.status && formik.errors.status && (
              <div className="p-error">{formik.errors.status}</div>
            )}
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-2">
            <label htmlFor="description" className="">
              {t("description")}
            </label>
            <span className=" w-full">
              <InputText
                id="description"
                name="description"
                className="w-full text-lg p-primary-input"
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.description && formik.errors?.description && (
            <div className="p-error">{formik.errors?.description}</div>
          )}
        </div>

        {/* <div>
          <div className="flex flex-col gap-2">
            <label htmlFor="vatPer" className="">
              {t("vat_percentage")}
            </label>
            <span className=" w-full">
              <InputText
                type="number"
                id="vatPer"
                name="vatPer"
                className="w-full text-lg p-primary-input"
                value={formik.values?.vatPer}
                onChange={formik.handleChange}
              />
            </span>
          </div>
        </div> */}

        {/* <div>
          <div className="flex flex-col gap-2">
            <label htmlFor="vatAmount" className="">
              {t("vat_amount")}
            </label>
            <span className=" w-full">
              <InputText
                disabled
                id="vatAmount"
                name="vatAmount"
                className="w-full text-lg p-primary-input"
                value={formik.values?.vatAmount}
                onChange={formik.handleChange}
              />
            </span>
          </div>
        </div> */}
      </div>

      {productId &&
      specificProduct &&
      specificProduct?.barcode_img &&
      specificProduct?.barcode_url ? (
        <div className="my-6 px-4 flex gap-8 justify-end">
          <div className="">
            <div
              id="receipt-component"
              style={{
                width: "38mm",
                height: "28mm",
              }}
            >
              <div className="flex font-bold mb-1 flex-col">
                <div className="flex justify-center text-base">
                  {specificProduct?.sub_category_name}
                </div>
                <div className="flex gap-3 justify-center text-xl">
                  <span>
                    SAR {formik?.values?.price?.toLocaleString("en-IN")}/-
                  </span>
                </div>
                <div className="flex gap-3 justify-center text-sm">
                  <span>Reys Solutions</span>
                </div>
              </div>
              <div className="flex justify-center px-3" style={{}}>
                <img
                  src={`${specificProduct?.barcode_url}${specificProduct?.barcode_img}`}
                  className=""
                  style={{ width: "36mm" }}
                />
              </div>
            </div>
          </div>
          <div className="my-auto">
            <Button
              label={t("print_barcode")}
              icon="pi pi-print"
              className="p-black-btn"
              onClick={handlePrint}
              type="button"
            />
          </div>
        </div>
      ) : null}
      <MultiLanguageNames
        handleChange={formik.handleChange}
        multiNames={formik.values.multiNames}
        name={formik.values.title}
        showMultiLangModal={showMultiLangModal}
        setShowMultiLangModal={setShowMultiLangModal}
      />
    </div>
  );
}
