import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";

import { useParams } from "react-router-dom";
import { searchProductWithInvoice } from "../../store/AsyncMethods/SalesMethod";
import { useTranslation } from "react-i18next";
import { getspecificCustomer } from "../../store/AsyncMethods/CustomerMethod";
export default function AddSalesForm({
  formik,
  isRefund,
  setIsRefund,
  isDisabled,
}) {
  const [searchProductInvoice, setSearchProductInvoice] = useState("");
  const { saleId } = useParams();
  const dispatch = useDispatch();
  const [dates, setDates] = useState({
    date: new Date(),
    due_date: new Date(),
    invoice_date: new Date(),
  });
  const { user } = useSelector((state) => state.AuthReducer);
  const { allCustomers } = useSelector((state) => state.CustomersReducer);
  const { specificCustomer } = useSelector((state) => state.CustomersReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);

  const { t } = useTranslation();

  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);

  const saleStatusOptions = [
    { name: t("invoice"), value: "invoice" },
    { name: t("refund"), value: "refund" },
  ];

  const handleSearchProductWithInvoice = () => {
    if (searchProductInvoice.length > 0) {
      dispatch(searchProductWithInvoice(searchProductInvoice)).then((pro) => {
        if (pro) {
          dates.date = new Date(pro.date);
          dates.due_date = new Date(pro.due_date);
          dates.invoice_date = new Date(pro.invoice_date);

          formik.setValues({
            name: pro.customer_name || "Customer",
            saleStatus: "refund",
            customer: pro.customer_id || "",

            currencyId: pro.currency_id || 1,

            date: dates.date || "",

            due_date: dates.due_date || "",
            invoice_date: dates.invoice_date || "",
            phone: pro.customer_phone || "03",
            totalPurchase: pro.total_purchase || 0,
            status: pro.is_active === 1 ? true : false,
            totalSales: pro.total_sales || 0,
            totalDiscount: pro.total_discount || 0,
            vatPer: pro.vat_per || 0,
            vatAmount: pro.vat_amount || 0,
            totalAmount: pro.total_amount || 0,
            userId: user.id,
            products: pro.products || [],
            posted: 0,
            product: {
              purchasePrice: 0,
              salePrice: 0,
              product: "",
              varient: "",
              discount: 0,
              quantity: 0,
              total: 0,
              discount_value: 0,
              is_discount_percentage: true,
            },
          });
          setIsRefund(true);
          setSearchProductInvoice("");
        }
      });
    }
  };
  useEffect(() => {
    if (formik.values.customer !== 0) {
      dispatch(getspecificCustomer(formik.values.customer));
    }
  }, [formik.values.customer]);

  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {saleId ? t("edit_customer") : t("add_customer")}
        </span>
      </Divider>

      {formik.values.saleStatus === "refund" ? (
        <div className="px-4 pb-12  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="w-full flex">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                placeholder={t("search_using_invoice_hash")}
                className="rounded-r-none pl-12 w-full"
                value={searchProductInvoice}
                onChange={(e) => setSearchProductInvoice(e.target.value)}
                onKeyUpCapture={(e) => {
                  e.preventDefault();
                  if (e.key === "Enter") {
                    handleSearchProductWithInvoice();
                  }
                }}
              />
            </span>
            <Button
              icon="pi pi-arrow-right"
              className="rounded-l-none p-black-btn"
              type="button"
              onClick={handleSearchProductWithInvoice}
            />
          </div>
        </div>
      ) : null}

      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="">
              {t("customer_name")}
            </label>
            <span className=" w-full">
              <InputText
                id="name"
                name="name"
                className="w-full text-lg p-primary-input"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.name && formik.errors?.name && (
            <div className="p-error">{formik.errors?.name}</div>
          )}
        </div> */}

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="lname" className="">
              "{t(Customer Last Name)}"
            </label>
            <span className=" w-full">
              <InputText
                id="lname"
                name="lname"
                className="w-full text-lg p-primary-input"
                value={formik.values.lname}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.lname && formik.errors?.lname && (
            <div className="p-error">{formik.errors?.lname}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="customer" className="">
              {t("Select Customer")}
            </label>

            <Dropdown
              disabled={isDisabled}
              id="customer"
              name="customer"
              className="!w-full text-lg p-primary-input"
              value={formik.values.customer}
              onChange={formik.handleChange}
              options={allCustomers}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-2" },
                filterInput: { className: "pl-8" },
              }}
            />
          </div>
          {formik.touched?.customer && formik.errors?.customer && (
            <div className="p-error">{formik.errors?.customer}</div>
          )}
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="subCategoryId" className="">
              "{t(Select Sub Category)}"
            </label>
            <Dropdown
              id="subCategoryId"
              name="subCategoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.subCategoryId}
              onChange={formik.handleChange}
              options={filterSubCategories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-1" },
                filterInput: { className: "pl-6" },
              }}
            />
          </div>
          {formik.touched?.subCategoryId && formik.errors?.subCategoryId && (
            <div className="p-error">{formik.errors?.subCategoryId}</div>
          )}
        </div> */}

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="price" className="">
{t(              Price)}
            </label>
            <span className=" w-full">
              <InputNumber
                id="price"
                name="price"
                className="w-full text-lg p-primary-input"
                value={formik.values.price}
                onValueChange={formik.handleChange}
                prefix={`${enableCurrency?.unit || ""} `}
                disabled={!formik.values.samePrice}
              />
            </span>
          </div>
          {formik.touched?.price && formik.errors?.price && (
            <div className="p-error">{formik.errors?.price}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="samePrice"
              name="samePrice"
              id="samePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePrice" className="ml-2">
              {t(Same Price for all varients)}
            </label>
          </div>
        </div> */}

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="phone" className="">
              {t("customer_phone_number")}
            </label>
            <span className=" w-full">
              <InputText
                id="phone"
                name="phone"
                className="w-full text-lg p-primary-input"
                value={formik.values?.phone}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.phone && formik.errors?.phone && (
            <div className="p-error">{formik.errors?.phone}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="vatNumber" className="">
              {t("vat_number")}
            </label>
            <span className=" w-full">
              <InputText
                disabled
                id="vatNumber"
                name="vatNumber"
                type="number"
                className="w-full text-lg p-primary-input"
                value={
                  specificCustomer?.vat_number
                    ? (formik.values.vatNumber = specificCustomer.vat_number)
                    : formik.values.vatNumber
                }
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.vatNumber && formik.errors?.vatNumber && (
            <div className="p-error">{formik.errors?.vatNumber}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="saleStatus" className="">
              {t("invoice_status")}
            </label>
            <SelectButton
              name="saleStatus"
              id="saleStatus"
              options={saleStatusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              disabled={isDisabled || (saleId || isRefund ? true : false)}
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center"
                    : "w-full text-lg text-center",
                }),
              }}
              value={formik.values.saleStatus}
              onChange={formik.handleChange}
            />
            {formik.touched.saleStatus && formik.errors.saleStatus && (
              <div className="p-error">{formik.errors.saleStatus}</div>
            )}
          </div>
        </div>
        <br></br>
        <div className=" ">
          <div className="flex flex-col  gap-2  p-fluid">
            <label htmlFor="date" className="">
              {t("date")}
            </label>

            <Calendar
              disabled={isDisabled}
              dateFormat="yy/mm/dd"
              id="date"
              onChange={formik.handleChange}
              className=" w-full calender p-primary-cld-btn"
              value={formik.values.date}
              showIcon
            />
          </div>
          {formik.touched?.date && formik.errors?.date && (
            <div className="p-error">{formik.errors?.date}</div>
          )}
        </div>
        <div className=" ">
          <div className="flex flex-col  gap-2">
            <label htmlFor="due-date" className="">
              {t("due_date")}
            </label>

            <Calendar
              disabled={isDisabled}
              dateFormat="yy/mm/dd"
              id="due_date"
              onChange={formik.handleChange}
              className=" w-full calender p-primary-cld-btn"
              value={formik.values.due_date}
              showIcon
            />
          </div>
          {formik.touched?.due_date && formik.errors?.due_date && (
            <div className="p-error">{formik.errors?.due_date}</div>
          )}
        </div>
        <div className="">
          <div className="flex flex-col  gap-2">
            <label htmlFor="invoice-date" className="">
              {t("invoice_date")}
            </label>

            <Calendar
              disabled={isDisabled}
              dateFormat="yy/mm/dd"
              id="invoice_date"
              onChange={formik.handleChange}
              className=" w-full calender p-primary-cld-btn"
              value={formik.values.invoice_date}
              showIcon
            />
          </div>
          {formik.touched?.invoice_date && formik.errors?.invoice_date && (
            <div className="p-error">{formik.errors?.invoice_date}</div>
          )}
        </div>
      </div>
    </div>
  );
}
