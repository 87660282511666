import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Decimal from "decimal.js-light";

export default function AddSalesPayment({ formik, isDisabled }) {
  const { allPaymentTypes } = useSelector(
    (state) => state.PaymentMethodReducer
  );
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);

  const { t } = useTranslation();

  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {t("sales_payment")}
        </span>
      </Divider>

      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="paymentTypeId" className="">
              {t("payment_type")}
            </label>

            <Dropdown
              disabled={isDisabled}
              id="paymentTypeId"
              name="paymentTypeId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.paymentTypeId}
              onChange={formik.handleChange}
              options={allPaymentTypes}
              optionLabel="VALUE"
              optionValue="IDINDEX"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-2" },
                filterInput: { className: "pl-8" },
              }}
            />
          </div>
          {formik.touched?.paymentTypeId && formik.errors?.paymentTypeId && (
            <div className="p-error">{formik.errors?.paymentTypeId}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="cashier" className="">
              {t("cashier")}
            </label>
            <span className=" w-full">
              <InputText
                disabled={isDisabled}
                id="cashier"
                name="cashier"
                className="w-full text-lg p-primary-input"
                value={formik.values.cashier}
                onChange={formik.handleChange}
              />
            </span>
          </div>
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="amountReceived" className="">
              {t("amount_received")}
            </label>
            <span className=" w-full">
              <InputNumber
                disabled={isDisabled}
                id="amountReceived"
                name="amountReceived"
                className="w-full text-lg p-primary-input"
                value={formik.values.amountReceived}
                onValueChange={formik.handleChange}
                prefix={`${enableCurrency?.unit || ""} `}
              />
            </span>
          </div>
          {formik.touched?.amountReceived && formik.errors?.amountReceived && (
            <div className="p-error">{formik.errors?.amountReceived}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="remainAmount" className="">
              {t("remain_amount")}
            </label>
            <span className=" w-full">
              <InputText
                disabled
                id="remain_amount"
                name="remain_amount"
                className="w-full text-lg p-primary-input"
                value={
                  formik.values.amountReceived > 0
                    ? (formik.values.remainAmount = new Decimal(
                        formik.values.totalAmount - formik.values.amountReceived
                      ).todp(2))
                    : new Decimal(formik.values.totalAmount).todp(2)
                }
                onChange={formik.handleChange}
                prefix={`${enableCurrency?.unit || ""} `}
              />
            </span>
            <span>
              <Checkbox
                inputId="isDiscount"
                disabled={isDisabled}
                name="isDiscount"
                onChange={(e) =>
                  formik.setFieldValue("isDiscount", e.target.checked)
                }
                checked={formik.values.isDiscount}
              />
              <label htmlFor="isDiscount" className="ml-2">
                {t("discount_apply_payment")}
              </label>
            </span>
          </div>
        </div>

        <br></br>
      </div>
    </div>
  );
}
