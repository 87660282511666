import { ProgressSpinner } from "primereact/progressspinner";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const PrivateRoutes = () => {
  const { user, token, loading } = useSelector((state) => state.AuthReducer);
  const css = `
  @keyframes p-progress-spinner-color {
      100%, 0% {
          stroke: #41bfc7;
      }
      
  }
`;
  return (
    <>
      {loading && (
        <div className="flex justify-center items-center z-50 w-full h-full fixed top-0 left-0 bg-white bg-opacity-50 ">
          <ProgressSpinner />
        </div>
      )}

      {token ? (
        user?.role_id === 1 ? (
          <Outlet />
        ) : (
          <Navigate to="/dashboard-sales" />
        )
      ) : (
        <Navigate to="/" />
      )}
      <style>{css}</style>
    </>
  );
};

export default PrivateRoutes;
