import { SET_COMPANY_DETAILS } from "../Types/CompanyTypes";

const initialState = {
  companyDetails: [],
};

const CompanyReducer = (state = initialState, action) => {
  if (action.type === SET_COMPANY_DETAILS) {
    return { ...state, companyDetails: action.payLoad };
  } else {
    return state;
  }
};

export default CompanyReducer;
