import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { getCompanyDetails } from "../../../store/AsyncMethods/CompanyMethod";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { getspecificVendor } from "../../../store/AsyncMethods/VendorMethod";
import { useTranslation } from "react-i18next";
export default function Invoice() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificPurchase } = useSelector((state) => state.PurchaseReducer);
  const { companyDetails } = useSelector((state) => state.CompanyReducer);
  const { specificVendor } = useSelector((state) => state.VendorReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  const { allVarients, products } = useSelector(
    (state) => state.ProductReducer,
  );

  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    if (specificPurchase) {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date(specificPurchase?.created_at);
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    } else {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date();
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [specificPurchase]);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    if (specificPurchase) {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date(specificPurchase?.created_at);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    } else {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    }
  }, [specificPurchase]);
  useEffect(() => {
    {
      dispatch(getCompanyDetails());
    }
  }, []);

  useEffect(() => {
    if (specificPurchase?.vendor_id) {
      dispatch(getspecificVendor(specificPurchase.vendor_id));
    }
  }, [specificPurchase]);

  const handleProduct = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product.article_name;
    }
    return ""; // Return an empty string if no matching product is found
  };

  const handleProductName = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product.title + "  /" + rowData.productLang;
    }
    return ""; // Return an empty string if no matching product is found
  };

  const handleVarient = (rowData) => {
    const varient = allVarients.find(
      (vari) => vari.varient_id === rowData.varient,
    );

    if (varient) {
      const parsedOptions = JSON.parse(varient.options);
      const optionLabel = parsedOptions.map((option) => {
        return `${option} - ${varient[option]?.value || "N/A"}`;
      });

      return optionLabel.map((op, i) => <div key={i}>{op}</div>);
    }

    return ""; // Return an empty string if no matching product is found
  };

  const [totalQty, setTotalQty] = useState(0);

  //   useEffect(() => {
  //     if (salesDetails) {
  //       let qty = 0;
  //       salesDetails.map((product) => {
  //         qty = qty + product.quantity;
  //       });
  //       setTotalQty(qty);
  //     }
  //   }, [salesDetails]);

  // useEffect(()=>{
  //   const vendor = vendors.find((vendor) => vendor.id === sales.vendor_id);
  //   if (Object.keys(vendor).length !== 0) {
  //     setvendor(vendor)
  //   } else {
  //     setvendor({});
  //   }
  //   const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //   if (Object.keys(staffs).length !== 0) {
  //     setSelectedStaff(staffs)
  //   } else {
  //     setSelectedStaff({})
  //   }
  // },[])

  //   useEffect(() => {
  //     const vendor = vendors.find(
  //       (vendor) => vendor.id === sales.vendor_id
  //     );
  //     if (vendor && Object.keys(vendor).length !== 0) {
  //       setvendor(vendor);
  //     } else {
  //       setvendor({});
  //     }

  //     const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //     if (staffs && Object.keys(staffs).length !== 0) {
  //       setSelectedStaff(staffs);
  //     } else {
  //       setSelectedStaff({});
  //     }
  //   }, []);
  const dataVendor = specificPurchase
    ? [
        {
          name: "Name",
          value: specificPurchase?.vendor_name,
          arabic: "الاسم",
        },
        {
          name: "Phone",
          value: specificPurchase?.vendor_phone,
          arabic: "الهاتف",
        },
        {
          name: "Address",
          value: specificPurchase?.vendor_address,
          arabic: "العنوان",
        },
        ,
        {
          name: "VAT No",
          value: specificPurchase?.vat_number,
          arabic: "رقم الضريبة",
        },
        {
          name: "CR No",
          value: "657456789",
          arabic: "رقم السجل التجاري",
        },
      ]
    : [];
  const totalAmount = specificPurchase
    ? [
        {
          name: `${specificPurchase?.products ? specificPurchase.products.length : 0} x Items Sold`,
          value: `${specificPurchase.total_sales} ${enableCurrency?.unit ? enableCurrency?.unit : ""}`,
          arabic:
            `اصناف المباعة` +
            `  x ${specificPurchase?.products ? specificPurchase.products.length : 0}`,
        },
        {
          name: "Discount",
          value: `${specificPurchase.total_discount} ${enableCurrency?.unit ? enableCurrency?.unit : ""}`,
          arabic: "التخفيض",
        },
        {
          name: `VAT (${specificPurchase?.vat_per ? specificPurchase.vat_per : 0}%)`,
          value: `${specificPurchase?.vat_amount}  ${enableCurrency?.unit ? enableCurrency?.unit : ""}`,
          arabic: `الضريبة (${specificPurchase?.vat_per ? specificPurchase.vat_per : 0}%)`,
        },
        ,
        {
          name: "Total",
          value: `${specificPurchase?.total_amount}   ${enableCurrency?.unit ? enableCurrency?.unit : ""}`,
          arabic: "المجموع",
        },
      ]
    : [];
  return (
    <div>
      <div>
        <div className="grid grid-cols-3 h-fit">
          {/* <div className="w-full scale-75 mt-6 leading-tight ">
            <div className="text-base font-bold flex justify-between mb-4">
              <span className="w-2/3">Name:</span>
              <span className=" w-1/3 text-left">
                {specificPurchase?.vendor_name ?? ""}
              </span>
              <span className="w-2/3 text-right">:اسم </span>
            </div>
            <div className="text-base  font-bold flex justify-between mb-4">
              <span className=" w-2/3 text-left">Phone:</span>
              <span className=" w-1/3 text-left">
                {specificPurchase?.vendor_phone ?? ""}
              </span>
              <span className=" w-2/3 text-right">:شماره تلفن </span>
            </div>
            <div className="text-base font-bold flex justify-between mb-4">
              <span className=" w-2/3 text-left">VAT:</span>
              <span className=" w-1/3  text-left">
                {specificPurchase?.vat_number ?? ""}
              </span>
              <span className=" w-2/3 text-right">:شماره مالیات </span>
            </div>
            <div className="text-base te font-bold flex mb-4">
              <span className=" w-2/4 text-left">Address:</span>
              <span className=" w-3/4  text-left">
                {specificPurchase?.vendor_address ?? ""}
              </span>
              <span className=" w-2/4 text-right">:عنوان البائع </span>
            </div>
          
          </div> */}
          <DataTable
            columnFilter="hidden"
            size="small"
            style={{ width: "98%" }}
            className=" text-sm pb-1 my-3 mt-8 mx-3"
            value={dataVendor}
            responsive={true}
          >
            <Column
              body={(rowData) => rowData.name.toLocaleString("en-IN") + ":"}
              headerStyle={{ display: "none" }}
              className=" item-total font-bold text-black "
            />
            <Column
              body={(rowData) => rowData.value.toLocaleString("en-IN")}
              headerStyle={{ display: "none" }}
              className=" item-total font-bold text-black "
            />
            <Column
              body={(rowData) => ":" + rowData.arabic.toLocaleString("en-IN")}
              headerStyle={{ display: "none" }}
              className="item-total text-right font-bold text-black "
            />
          </DataTable>
          <div className=" mt-9 ">
            <div className="w-full flex  justify-center">
              <img src="/images/logo.png" className="w-24 mt-1" />
            </div>

            <div className="w-full mt-1 scale-75 leading-tight text-sm">
              <div className="text-center font-bold">
                Near Khawajgan Trust, Khawajgan Road, Gujrat
              </div>
              <div className="text-center font-bold">
                help@reyssolutions.com | 0322-5991588
              </div>
            </div>
            <div className="mt-3">
              <div className="text-center text-base font-bold">
                {specificPurchase?.status === "refund" ? "REFUND" : "SALES"}{" "}
                INVOICE{" "}
                {specificPurchase
                  ? ` # ${String(specificPurchase?.id).padStart(5, "0")}`
                  : null}{" "}
                {/* <div className="">{specificPurchase?.status === "استرداد" ? "استرداد" : "مبيعات"}</div> */}
                {/* {"  "}
              إيصال {} */}
              </div>
            </div>
          </div>
          <div className="flex justify-end p-3 mt-6 ">
            {specificPurchase && specificPurchase?.qr_text ? (
              <QRCode size={100} value={specificPurchase?.qr_text} />
            ) : null}
          </div>
        </div>

        <div className="w-full flex justify-center mt-2 receipt">
          <DataTable
            showGridlines
            size="small"
            className=" text-sm pb-1 my-3"
            value={specificPurchase?.products}
            style={{ width: "98%" }}
          >
            <Column
              header={
                <div className="flex flex-col ">
                  <div>Title</div>
                  <div className=" text-left"> اسم المنتج</div>
                </div>
              }
              className="w-4/12 item-name font-bold text-black"
              headerClassName="text-sm font-bold text-black "
              body={handleProductName}
            />

            <Column
              header={
                <div className="flex flex-col">
                  <div>Product</div>
                  <div>منتج</div>
                </div>
              }
              className="w-2/12 item-name font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={handleProduct}
            />
            {/* <Column
            header="Varient"
            className="w-5/12 item-name"
            headerClassName="text-sm"
            body={handleVarient}
          /> */}

            <Column
              header={
                <div className="flex flex-col">
                  <div>Quantity</div>
                  <div>كمية</div>
                </div>
              }
              className="w-2/12 item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.quantity}
            />
            <Column
              header={
                <div className="flex flex-col">
                  <div>Price</div>
                  <div>سعر</div>
                </div>
              }
              className="w-2/12 item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.salePrice.toLocaleString("en-IN")}
            />
            <Column
              body={(rowData) => rowData.discount.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <div>Discount</div>
                  <div>تخفيض</div>
                </div>
              }
              cla
              className="w-2/12 item-total font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
            />
            <Column
              body={(rowData) => rowData.total.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <div>Total</div>
                  <div>المجموع</div>
                </div>
              }
              className="w-2/12 item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold text-black"
            />
          </DataTable>
        </div>
        <div className="w-full flex justify-center mt-2 receipt">
          <DataTable
            showGridlines
            size="small"
            className=" text-sm pb-1 my-3"
            value={totalAmount}
            style={{ width: "98%" }}
          >
            <Column
              header="Total Amount"
              className="w-4/12 item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black bg-gray-400"
              body={" "}
            />

            <Column
              className="w-2/12 item-price font-bold text-black text-left"
              headerClassName="text-sm font-bold text-black bg-gray-400"
              body={(rowData) => rowData.name.toLocaleString("en-IN")}
            />
            <Column
              body={(rowData) => rowData.arabic.toLocaleString("en-IN")}
              className="w-2/12 item-total font-bold text-black text-right"
              headerClassName="text-sm font-bold bg-gray-400 text-black"
            />
            <Column
              header={"المجموع"}
              body={(rowData) => rowData.value.toLocaleString("en-IN")}
              className="w-1/12 item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold bg-gray-400 text-black  "
            />
          </DataTable>
        </div>

        {/* <div className="flex justify-center mx-auto" style={{ width: "98%" }}>
        <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
          <div>Cash</div>
          <div>
            {salesPayment
              ? salesPayment.paid_amount.toLocaleString("en-IN")
              : ""}
          </div>
        </div>
      </div>

      <div className="flex justify-center mx-auto" style={{ width: "98%" }}>
        <div className="flex justify-between w-full text-sm font-bold mx-1">
          <div>Change</div>
          <div>
            {salesPayment
              ? salesPayment.return_amount.toLocaleString("en-IN")
              : ""}
          </div>
        </div>
      </div> */}

        {/* <div className="text-center font-bold mt-3 leading-tight">
        <div className="text-base mb-3">
          Change Policy: Only in one week (7 Days)
        </div>
        <div className="text-xl">THANK YOU!</div>
        <div className="text-sm mt-2">Follow us on Social Media</div>
      </div> */}

        {/* <div className="text-center text-xl mt-2 flex gap-1 justify-center">
        <FaWhatsapp />
        <FaFacebook />
        <FaInstagram />
        <FaPinterest />
        <FaTwitter />
      </div> */}

        <div className=" flex flex-col font-bold item-total text-base text-black   p-4 min-w-full ">
          <span>Notes (ملاحظاتة) </span>
          <span>Thanks for the your Business.</span>
        </div>

        <div className=" flex flex-col font-bold item-total text-base text-black text-right  p-4 min-w-full absolute bottom-20 ">
          <div>
            {companyDetails?.name && (
              <span>
                {companyDetails.name_eng.toLocaleString("en-IN")}
                {" | "}
                {companyDetails.name}
              </span>
            )}
          </div>
          <span className=" ">
            {companyDetails?.email
              ? companyDetails?.email.toLocaleString("en-IN")
              : ""}
          </span>
          <span className=" ">
            {companyDetails?.phone
              ? companyDetails?.phone.toLocaleString("en-IN")
              : ""}
          </span>
          <span className=" ">
            {companyDetails?.address
              ? companyDetails?.address.toLocaleString("en-IN")
              : ""}
          </span>
        </div>

        <div className="w-full absolute bottom-0 flex justify-center my-8 mt-4">
          <div
            className=" font-bold flex justify-between text-sm"
            style={{ width: "98%" }}
          >
            {/* <span className="">ID: {sales ? sales.id : ""}</span> */}
            <span className="w-2/3">Checked By {user.first_name}</span>
            <span className="w-1/3 text-center">{currentDate}</span>
            <span className="w-2/3 text-right">{currentTime}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
