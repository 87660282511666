export const SET_CURRENCY = "SET_CURRENCY";
export const RESET_CURRENCY = "RESET_CURRENCY";
export const SET_SPECIFIC_CURRENCY = "SET_SPECIFIC_CURRENCY";
export const RESET_SPECIFIC_CURRENCY = "RESET_SPECIFIC_CURRENCY";
export const SET_ENABLE_CURRENCY = "SET_ENABLE_CURRENCY";
export const RESET_ENABLE_CURRENCY = "RESET_ENABLE_CURRENCY";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";
export const RESET_EXCHANGE_RATE = "RSSET_EXCHANGE_RATE";
export const SET_SPECIFIC_EXCHANGE_RATE = "SET_SPECIFIC_EXCHANGE_RATE";
export const RESET_SPECIFIC_EXCHANGE_RATE = "RESET_SPECIFIC_EXCHANGE_RATE";
