import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { addCustomerBalance, getCustomers } from "../../store/AsyncMethods/CustomerMethod";

import * as Yup from "yup";
import { addVendorBalance } from "../../store/AsyncMethods/VendorMethod";

export default function VendorBalance() {
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
const  {allVendors} = useSelector(state => state.VendorReducer);
const dispatch = useDispatch();
  
useEffect(()=>{
dispatch(getCustomers())
},[])
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
     
      vendor: "",
      date: new Date(),
      siteId:"",
      creditAmount:"",
      debitAmount:"",
      currencyId: enableCurrency?.currency_id || 1,
    
    },
    validationSchema: Yup.object({
     
      vendor: Yup.number().required("Vendor Required"),
      date: Yup.string().required("Date Required"),
      creditAmount: Yup.number().required("Credit Amount Required"),
      debitAmount: Yup.number().required("Debit Amount Required"),
    }),

    onSubmit: async (data) => {
    
       console.log("data", data);
        dispatch(addVendorBalance(data)).then((success) => {
          if (success.success) {
            formik.resetForm();
            
          }
        });
      
    },
  });
  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {t("vendor_opening_balance")}
        </span>
      </Divider>

      <form onSubmit={formik.handleSubmit}>

      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2">
     

      <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="vendor" className="">
              {t("Select Vendor")}
            </label>

            <Dropdown
              id="vendor"
              name="vendor"
              className="!w-full text-lg p-primary-input"
              value={formik.values.vendor}
              onChange={formik.handleChange}
              options={allVendors}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-2" },
                filterInput: { className: "pl-8" },
              }}
            />
          </div>
          {formik.touched?.vendor && formik.errors?.vendor && (
            <div className="p-error">{formik.errors?.vendor}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="siteId" className="">
              {t("site_id")}
            </label>
            <span className=" w-full">
              <InputText
                 disabled
                id="siteId"
                name="siteId"
                className="w-full text-lg p-primary-input"
                value={
                  formik.values.siteId
                }
                onChange={formik.handleChange}
              />
            </span>
          </div>
          
        </div>
        
        <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="creditAmount" className="">
                {t("credit_amount")}
              </label>
              <span className=" w-full">
                <InputNumber
                  
                  id="creditAmount"
                  
                  name="creditAmount"
                  className="w-full text-lg p-primary-input"
                  value={formik.values.creditAmount}
                  onValueChange={formik.handleChange}
                  prefix={`${enableCurrency?.unit || ""} `}
                />
              </span>
            </div>
            {formik.touched?.creditAmount&&
              formik.errors?.creditAmount && (
                <div className="p-error">
                  {formik.errors?.creditAmount}
                </div>
              )}
          </div>
          
          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="debitAmount" className="">
                {t("debit_amount")}
              </label>
              <span className=" w-full">
                <InputNumber
                  
                  id="debitAmount"
                  
                  name="debitAmount"
                  className="w-full text-lg p-primary-input"
                  value={formik.values.debitAmount}
                  onValueChange={formik.handleChange}
                  prefix={`${enableCurrency?.unit || ""} `}
                />
              </span>
            </div>
            {formik.touched?.debitAmount&&
              formik.errors?.debitAmount && (
                <div className="p-error">
                  {formik.errors?.debitAmount}
                </div>
              )}
          </div>
      
        <div className=" ">
                  <div className="flex flex-col gap-2 p-fluid">
                    <label htmlFor="date" className="">
                      {t("open_balance_date")}
                    </label>

                    <Calendar
                      dateFormat="yy/mm/dd"
                      id="date"
                      onChange={formik.handleChange}
                      className=" w-full calender p-primary-cld-btn"
                      value={formik.values.date}
                      showIcon
                    />
                  </div>
                  {formik.touched?.date && formik.errors?.date && (
                    <div className="p-error">{formik.errors?.date}</div>
                  )}
                </div>
       
              
                <div className="mt-16">
            <div className="flex justify-end gap-4">
              <Button
                label={t("cancel")}
                icon="pi pi-times"
                className="p-red-btn"
                type="button"
                onClick={() => {
                  formik.resetForm();
                }}
              />
              <Button
                label={t("submit")}
                type="submit"
                icon="pi pi-check"
                className="p-secondary-btn"
              />
            </div>
          </div>
      </div>
      
      </form>
      
    </div>
  );
}
