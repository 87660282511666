import {
    CLOSE_LOADER,
    SET_ERROR,
    SET_LOADER,
    SET_SUCCESS,
  } from "../Types/AuthTypes";
  import backendVerifiedUser from "../../api/backendVerifiedUser";
  import { SET_PAYMENT_METHOD } from "../Types/PaymentMethodTypes";
  
  export const getPaymentMethod = () => {
    return async (dispatch) => {
      dispatch({ type: SET_LOADER });
      try {
        const response = await backendVerifiedUser.get("/get-payment-method-types");
  
        dispatch({ type: CLOSE_LOADER });
  
        dispatch({ type: SET_PAYMENT_METHOD, payLoad: response.data });
      } catch (err) {
        dispatch({ type: CLOSE_LOADER });
        console.log(err);
        dispatch({
          type: SET_ERROR,
          payLoad: err.response?.data?.error?.msg,
        });
      }
    };
  };
 