import {
   SET_PAYMENT_METHOD
  } from "../Types/PaymentMethodTypes";
  
  const initialState = {
    allPaymentTypes: [],
  };
  
  const PaymentMethodReducer = (state = initialState, action) => {
    if (action.type === SET_PAYMENT_METHOD) {
      return { ...state, allPaymentTypes: action.payLoad };
    }  else {
      return state;
    }
  };
  
  export default PaymentMethodReducer;
  