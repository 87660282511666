import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./scss/index.scss";
import Store from "./store";
import { Provider } from "react-redux";
import Home from "./components/Home";
import Login from "./components/Auth/Login";
import AuthRoutes from "./protectedRoutes/AuthRoutes";
import PrivateRoutes from "./protectedRoutes/PrivateRoutes";
import RootLayout from "./layouts/RootLayout";
import DashboardMain from "./pages/Dashboard/DashboardMain";
import CategoriesMain from "./pages/Categories/CategoriesMain";
import AddCategories from "./pages/Categories/AddCategories";
import EditCategories from "./pages/Categories/EditCategories";
import AddSubCategories from "./pages/SubCategories/AddSubCategories";
import SubCategoriesMain from "./pages/SubCategories/SubCategoriesMain";
import EditSubCategories from "./pages/SubCategories/EditSubCategories";
import ProductMain from "./pages/Products/ProductMain";
import AddProduct from "./pages/Products/AddProduct";
import ManageUserMain from "./pages/ManageUser/ManageUserMain";
import AddUser from "./pages/ManageUser/AddUser";
import SetNewPassword from "./components/Auth/SetNewPassword";
import SalesMain from "./pages/Sales/SalesMain";
import AddSales from "./pages/Sales/AddSales";
import SalesReport from "./pages/Sales/SalesReport";
import PurchaseReport from "./pages/Purchases/PurchaseReport";
import SalesReportTable from "./pages/Sales/SalesReportTable";
import PurchaseReportTable from "./pages/Purchases/PurchaseReportTable";
import SalesRoutes from "./protectedRoutes/SalesRoutes";
import SaleInvoiceMain from "./pages/Invoice/SaleInvoice/SaleInvoiceMain";
import CurrencyMain from "./pages/Currency/CurrencyMain";
import AddCurrency from "./pages/Currency/AddCurrency";
import ExchangeRatesMain from "./pages/ExchangeRates/ExchangeRatesMain";
import AddExchangeRates from "./pages/ExchangeRates/AddExchangeRates";
import AddCustomers from "./pages/Customers/AddCustomers";
import CustomersMain from "./pages/Customers/CustomersMain";
import AddVendor from "./pages/Vendors/AddVendor";
import VendorMain from "./pages/Vendors/VendorMain";
import PurchaseMain from "./pages/Purchases/PurchaseMain";
import AddPurchase from "./pages/Purchases/AddPurchase";
import PurchaseInvoiceMain from "./pages/Invoice/PurchaseInvoice/PurchaseInvoiceMain";
import VatDetailMain from "./pages/Setup/VatDetailMain";
import VatDetailTable from "./pages/Setup/VatDetailTable";
import AddVatDetail from "./pages/Setup/AddVatDetail";
import ProductReport from "./pages/Products/ProductReport";
import CashReceipt from "./pages/Reports/CashReceipt";
import CustomerStatement from "./pages/Reports/CustomerStatement";
import CustomerBalance from "./pages/Customers/CustomerBalance";
import VendorBalance from "./pages/Vendors/VendorBalance";
import CompanyDetails from "./pages/Setup/CompanyDetails";

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <Routes>
          <Route
            element={
              <RootLayout>
                <PrivateRoutes />
              </RootLayout>
            }
          >
            <Route path="/dashboard" exact element={<DashboardMain />} />
            <Route path="/categories" exact element={<CategoriesMain />} />
            <Route path="/add-categories" exact element={<AddCategories />} />
            <Route
              path="/sub-categories"
              exact
              element={<SubCategoriesMain />}
            />
            <Route
              path="/add-sub-categories"
              exact
              element={<AddSubCategories />}
            />
            <Route
              path="/edit-categories/:id"
              exact
              element={<EditCategories />}
            />
            <Route
              path="/edit-sub-categories/:id"
              exact
              element={<EditSubCategories />}
            />
            <Route path="/products" exact element={<ProductMain />} />
            <Route path="/add-product" exact element={<AddProduct />} />
            <Route
              path="/edit-product/:productId"
              exact
              element={<AddProduct />}
            />
            {/* <Route path="/manage-users" exact element={<ManageUserMain />} />
            <Route path="/add-user" exact element={<AddUser />} />
            <Route path="/edit-user/:userId" exact element={<AddUser />} /> */}
            <Route path="/sales" exact element={<SalesMain />} />
            <Route path="/add-sales" exact element={<AddSales />} />
            <Route path="/edit-sales/:saleId" exact element={<AddSales />} />
            <Route
              path="/invoice-sales/:saleId/:isPdfDownload"
              exact
              element={<SaleInvoiceMain />}
            />
            <Route
              path="/invoice-sales/:saleId"
              exact
              element={<SaleInvoiceMain />}
            />
            <Route path="/manage-currency" exact element={<CurrencyMain />} />
            <Route path="/add-currency" exact element={<AddCurrency />} />
            <Route
              path="/edit-currency/:currencyId"
              exact
              element={<AddCurrency />}
            />
            <Route
              path="/manage-exchange-rate"
              exact
              element={<ExchangeRatesMain />}
            />
            <Route
              path="/add-exchange-rate"
              exact
              element={<AddExchangeRates />}
            />
            <Route
              path="/edit-exchange-rate/:exchangeRateId"
              exact
              element={<AddExchangeRates />}
            />
            <Route path="/manage-customers" exact element={<CustomersMain />} />
            <Route path="/add-customers" exact element={<AddCustomers />} />
            <Route
              path="/edit-customer/:customerId"
              exact
              element={<AddCustomers />}
            />
            <Route path="/manage-vendor" exact element={<VendorMain />} />
            <Route path="/add-vendor" exact element={<AddVendor />} />
            <Route
              path="/edit-vendor/:vendorId"
              exact
              element={<AddVendor />}
            />
            <Route
              path="/manage-sales-report"
              exact
              element={<SalesReport />}
            />
            <Route
              path="/sale-report-result"
              exact
              element={<SalesReportTable />}
            />{" "}
            <Route
              path="/purchas-report-result"
              exact
              element={<PurchaseReportTable />}
            />
            <Route
              path="/manage-purchases-report"
              exact
              element={<PurchaseReport />}
            />
            <Route path="/purchase" exact element={<PurchaseMain />} />
            <Route path="/add-purchase" exact element={<AddPurchase />} />
            <Route
              path="/edit-purchase/:purchaseId"
              exact
              element={<AddPurchase />}
            />
            <Route
              path="/invoice-purchase/:purchaseId"
              exact
              element={<PurchaseInvoiceMain />}
            />
            <Route path="/setup-vat-detail" exact element={<VatDetailMain />} />
            <Route
              path="/setup-company-detail"
              exact
              element={<CompanyDetails />}
            />
            <Route
              path="/setup-add-vat-detail"
              exact
              element={<AddVatDetail />}
            />
            <Route
              path="/setup-edit-vat-detail/:vatId"
              exact
              element={<AddVatDetail />}
            />
            <Route
              path="/manage-item-report"
              exact
              element={<ProductReport />}
            />
            <Route
              path="/manage-cash-receipt"
              exact
              element={<CashReceipt />}
            />
            <Route
              path="/manage-customer-statement"
              exact
              element={<CustomerStatement />}
            />
            <Route
              path="/manage-customer-opening-balance"
              exact
              element={<CustomerBalance />}
            />
            <Route
              path="/manage-vendor-opening-balance"
              exact
              element={<VendorBalance />}
            />
          </Route>

          <Route
            element={
              <RootLayout>
                <SalesRoutes />
              </RootLayout>
            }
          >
            <Route
              path="/dashboard-sales"
              exact
              element={<div>Dashboard</div>}
            />
            <Route
              path="/categories-sales"
              exact
              element={<CategoriesMain />}
            />
            <Route
              path="/add-categories-sales"
              exact
              element={<AddCategories />}
            />
            <Route
              path="/sub-categories-sales"
              exact
              element={<SubCategoriesMain />}
            />
            <Route
              path="/add-sub-categories-sales"
              exact
              element={<AddSubCategories />}
            />
            <Route
              path="/edit-categories-sales/:id"
              exact
              element={<EditCategories />}
            />
            <Route
              path="/edit-sub-categories-sales/:id"
              exact
              element={<EditSubCategories />}
            />
            <Route path="/products-sales" exact element={<ProductMain />} />
            <Route path="/add-product-sales" exact element={<AddProduct />} />
            <Route
              path="/edit-product-sales/:productId"
              exact
              element={<AddProduct />}
            />
            <Route path="/sales-sales" exact element={<SalesMain />} />
            <Route path="/add-sales-sales" exact element={<AddSales />} />
            <Route
              path="/edit-sales-sales/:saleId"
              exact
              element={<AddSales />}
            />
            <Route
              path="/invoice-sales-sales/:saleId"
              exact
              element={<SaleInvoiceMain />}
            />
          </Route>

          <Route path="/" element={<AuthRoutes />}>
            <Route index element={<Login />} />
          </Route>

          <Route
            path="/users/:id/set-new-password/:token"
            element={<SetNewPassword />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
