import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { Tafgeet } from "tafgeet-arabic";

import { ToWords } from "to-words";
import { getspecificCustomer } from "../../../store/AsyncMethods/CustomerMethod";
import { useTranslation } from "react-i18next";
import { getCompanyDetails } from "../../../store/AsyncMethods/CompanyMethod";

export default function Invoice() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { companyDetails } = useSelector((state) => state.CompanyReducer);
  const { specificCustomer } = useSelector((state) => state.CustomersReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  const { allVarients, products } = useSelector(
    (state) => state.ProductReducer
  );
  const stringText = new Tafgeet(27421.75, "SAR").parse();
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    if (specificSale) {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date(specificSale?.created_at);
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    } else {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date();
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [specificSale]);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    if (specificSale) {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date(specificSale?.created_at);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    } else {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    }
  }, [specificSale]);

  useEffect(() => {
    if (specificSale?.customer_id) {
      dispatch(getspecificCustomer(specificSale.customer_id));
    }
  }, [specificSale]);
  useEffect(() => {
    {
      dispatch(getCompanyDetails());
    }
  }, []);

  const handleProduct = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product.article_name;
    }
    return ""; // Return an empty string if no matching product is found
  };
  const handleProductUOM = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product?.uom;
    }
    return ""; // Return an empty string if no matching product is found
  };
  const handleProductUnitPrice = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product?.purchase_price;
    }
    return ""; // Return an empty string if no matching product is found
  };

  const handleProductName = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product.title + "  /" + rowData.productLang;
    }
    return ""; // Return an empty string if no matching product is found
  };

  const handleVarient = (rowData) => {
    const varient = allVarients.find(
      (vari) => vari.varient_id === rowData.varient
    );

    if (varient) {
      const parsedOptions = JSON.parse(varient.options);
      const optionLabel = parsedOptions.map((option) => {
        return `${option} - ${varient[option]?.value || "N/A"}`;
      });

      return optionLabel.map((op, i) => <div key={i}>{op}</div>);
    }

    return ""; // Return an empty string if no matching product is found
  };
  const toWords = new ToWords({
    localeCode: "en-AE",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Riyal",
        plural: "Riyal",
        symbol: "ر.س",
        fractionalUnit: {
          name: "Halala",
          plural: "Halala",
          symbol: "",
        },
      },
    },
  });

  const [totalQty, setTotalQty] = useState(0);

  //   useEffect(() => {
  //     if (salesDetails) {
  //       let qty = 0;
  //       salesDetails.map((product) => {
  //         qty = qty + product.quantity;
  //       });
  //       setTotalQty(qty);
  //     }
  //   }, [salesDetails]);

  // useEffect(()=>{
  //   const customer = vendors.find((customer) => customer.id === sales.vendor_id);
  //   if (Object.keys(customer).length !== 0) {
  //     setvendor(customer)
  //   } else {
  //     setvendor({});
  //   }
  //   const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //   if (Object.keys(staffs).length !== 0) {
  //     setSelectedStaff(staffs)
  //   } else {
  //     setSelectedStaff({})
  //   }
  // },[])

  //   useEffect(() => {
  //     const customer = vendors.find(
  //       (customer) => customer.id === sales.vendor_id
  //     );
  //     if (customer && Object.keys(customer).length !== 0) {
  //       setvendor(vendor);
  //     } else {
  //       setvendor({});
  //     }

  //     const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //     if (staffs && Object.keys(staffs).length !== 0) {
  //       setSelectedStaff(staffs);
  //     } else {
  //       setSelectedStaff({});
  //     }
  //   }, []);
  const dataColInvoice = specificSale
    ? [
        {
          oInvoiceNo: "",
          invoiceNo: specificSale?.id,
          customerNo: specificCustomer?.id,
          invoiceDate: specificSale?.invoice_date,
          pageNo: "1",
        },
      ]
    : [];

  const dataColDel = specificSale
    ? [
        {
          delNO: specificSale?.del_no,
          delDate: specificSale?.del_date,

          customerPo: specificCustomer?.custpo,

          salesman: specificSale?.salesman,

          dueDate: specificSale?.due_date,
        },
      ]
    : [];
  const dataCustVat = specificSale
    ? [
        {
          name: "Customer Group VAT#" + specificCustomer?.group_vat,
        },
        {
          name: "Customer VAT# " + specificCustomer?.vat_number,
        },
        {
          name: specificCustomer?.postal_code,
        },
        ,
        {
          name: "Order Number# " + specificSale?.order_no,
        },
      ]
    : [];

  const dataShip = specificSale
    ? [
        {
          name: specificCustomer?.name,
          arabic: specificCustomer?.customerLanguages[1],
        },
        {
          name: specificCustomer?.building,

          arabic: specificCustomer?.building_ar,
        },
        {
          name: specificCustomer?.postal_code,

          arabic: specificCustomer?.postal_code_ar,
        },
        ,
        {
          name: specificCustomer?.country,

          arabic: specificCustomer?.country_ar,
        },
      ]
    : [];
  const dataBill = specificSale
    ? [
        {
          name: specificCustomer?.name,
          arabic: specificCustomer?.customerLanguages[1],
        },
        {
          name: specificCustomer?.building,

          arabic: specificCustomer?.building_ar,
        },
        {
          name: specificCustomer?.postal_code,

          arabic: specificCustomer?.postal_code_ar,
        },
        ,
        {
          name: specificCustomer?.country,

          arabic: specificCustomer?.country_ar,
        },
      ]
    : [];
  const dataSeller = specificSale
    ? [
        {
          name: companyDetails?.name,
          arabic: companyDetails?.name_ar,
        },
        {
          name: "Tel: " + companyDetails?.tel,

          arabic: companyDetails?.tel + " :" + "هاتف",
        },
        {
          name: "Fax: " + companyDetails?.fax,

          arabic: companyDetails?.fax + " :" + "فاكس",
        },
        ,
        {
          name: companyDetails?.address,

          arabic: companyDetails?.address_ar,
        },
        {
          name: companyDetails?.building,

          arabic: companyDetails?.building_ar,
        },
        {
          name: companyDetails?.po,

          arabic: companyDetails?.po_ar,
        },
        {
          name: companyDetails?.country,

          arabic: companyDetails?.country_ar,
        },
        {
          name: "Vat No: " + companyDetails?.vat,

          arabic: companyDetails?.vat + " :" + "رقم ضريبة القيمة المضافة",
        },
      ]
    : [];
  const totalAmount = specificSale
    ? [
        {
          name:
            "Total Excl.Vat " +
            "(" +
            `${enableCurrency?.unit ? enableCurrency?.unit : ""}` +
            ")",
          value: `${specificSale.total_sales} `,
          arabic: "الإجمالي باستثناء ضريبة القيمة المضافة",
        },
        {
          name:
            "Total VAT " +
            "(" +
            `${enableCurrency?.unit ? enableCurrency?.unit : ""}` +
            ")",
          value: `${specificSale.vat_amount} `,
          arabic: "إجمالي ضريبة القيمة المضافة",
        },
        {
          name:
            "Total Discount Rebate " +
            "(" +
            `${enableCurrency?.unit ? enableCurrency?.unit : ""}` +
            ")",
          value: `${specificSale?.total_discount}  `,
          arabic: "إجمالي الخصم الخصم",
        },
        ,
        {
          name:
            "Total including VAT  " +
            "(" +
            `${enableCurrency?.unit ? enableCurrency?.unit : ""}` +
            ")",
          value: `${specificSale?.total_amount} `,
          arabic: "الإجمالي شاملاً ضريبة القيمة المضافة",
        },
      ]
    : [];

  return (
    <div className="">
      <div className="content">
        <div className="  flex justify-between h-fit text-semibold">
          <div className="flex  items-center justify-between w-2/3">
            <div className="w-full md:w-1/2 xl:w-2/3 px-4">
              {dataSeller.map((item, index) => (
                <div key={index} className="flex flex-wrap items-center">
                  <p className="text-base ">{item.name}</p>
                </div>
              ))}
            </div>
            <div className="w-full md:w-1/2 xl:w-2/3 px-4 ">
              {dataSeller.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-wrap items-center justify-end"
                >
                  <p className="text-base  ">{item.arabic}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end p-3 mt-6  ">
            {specificSale && specificSale?.qr_text ? (
              <QRCode size={200} value={specificSale?.qr_text} />
            ) : null}
          </div>
        </div>

        <div className="w-full flex justify-start mx-3 mt-2 receipt">
          <DataTable
            showGridlines
            size="small"
            className=" text-sm  pb-1 my-3 "
            value={dataColInvoice}
            style={{ width: "78%" }}
          >
            <Column
              header={
                <div className="flex flex-col ">
                  <p className=" ">Payment Terms</p>
                  <p className=" flex justify-end">شروط الدفع</p>
                </div>
              }
              className=" item-name font-bold text-black"
              headerClassName="text-sm font-bold text-black "
              body={(rowData) => rowData.oInvoiceNo}
            />

            <Column
              header={
                <div className="flex flex-col">
                  <p className=" ">Invoice No</p>
                  <p className=" flex justify-end">رقم الفاتورة</p>
                </div>
              }
              className=" item-name font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.invoiceNo}
            />

            <Column
              header={
                <div className="flex flex-col">
                  <p className="">Customer No</p>
                  <p className="flex justify-end">رقم العميل</p>
                </div>
              }
              className=" item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.customerNo}
            />
            <Column
              header={
                <div className="flex flex-col">
                  <p className="">Invoice Date</p>
                  <p className="flex justify-end">تاريخ الفاتورة</p>
                </div>
              }
              className=" item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.invoiceDate}
            />
            <Column
              body={(rowData) => rowData.pageNo}
              header={
                <div className="flex flex-col">
                  <div>Page No</div>
                  <div>رقم الصفحة</div>
                </div>
              }
              className=" item-total font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
            />
          </DataTable>
        </div>
        <div className="flex  justify-center">
          {specificSale ? (
            <div className="w-full  p-4">
              <h2 className="font-bold  text-base">Ship To:</h2>
              <ul>
                {dataShip.map((item, index) => (
                  <li className=" flex flex-col font-sans" key={index}>
                    <span className=" ">{item.name}</span> {item.arabic}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {specificSale ? (
            <div className="w-full  p-4">
              <h2 className="font-bold text-2xl mt-6 text-center">
                TAX INVOICE
              </h2>
              <h2 className="font-bold text-base  text-center mb-10">
                فاتورة ضريبية
              </h2>

              <ul>
                {dataCustVat.map((item, index) => (
                  <li key={index}>
                    <span className="text-base font-sans">{item.name}</span>{" "}
                    {item.arabic}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {specificSale ? (
            <div className="w-full  p-4">
              <h2 className="font-bold text-base">Bill To:</h2>
              <ul>
                {dataBill.map((item, index) => (
                  <li className="flex flex-col font-sans" key={index}>
                    <span className="">{item.name}</span> {item.arabic}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center mt-2 receipt">
          <DataTable
            showGridlines
            size="small"
            className=" text-sm pb-1 my-3"
            value={dataColDel}
            style={{ width: "98%" }}
          >
            <Column
              header={
                <div className="flex flex-col ">
                  <p>Del No</p>
                  <p>رقم الشحنه</p>
                </div>
              }
              className=" item-name font-bold text-black"
              headerClassName="text-sm font-bold text-black "
              body={(rowData) => rowData.delNO}
            />

            <Column
              header={
                <div className="flex flex-col">
                  <p>Del Date</p>
                  <p>تاريخ التسليم او الوصول</p>
                </div>
              }
              className=" item-name font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.delDate}
            />

            <Column
              header={
                <div className="flex flex-col">
                  <p>Customer PO</p>
                  <p>رقم طلب الشراء</p>
                </div>
              }
              className=" item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.customerPo}
            />
            <Column
              header={
                <div className="flex flex-col">
                  <p>Salesman</p>
                  <p>بائع</p>
                </div>
              }
              className=" item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData.salesman}
            />

            <Column
              body={(rowData) => rowData.dueDate}
              header={
                <div className="flex flex-col">
                  <p>Due Date</p>
                  <p>تاريخ الاستحقاق</p>
                </div>
              }
              className=" item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold text-black"
            />
          </DataTable>
        </div>

        <div className="w-full flex justify-center mt-2 receipt">
          <DataTable
            showGridlines
            size="small"
            className=" text-sm pb-1 my-3"
            value={specificSale?.products}
            style={{ width: "98%" }}
          >
            <Column
              header={
                <div className="flex flex-col ">
                  <p>Description</p>
                  <p>وصف</p>
                </div>
              }
              className=" w-3/12 item-name font-bold text-black"
              headerClassName="text-sm font-bold text-black "
              body={handleProductName}
            />
            <Column
              header={
                <div className="flex flex-col ">
                  <p>Item#</p>
                  <p>رقم الشيء</p>
                </div>
              }
              className="  item-name font-bold text-black"
              headerClassName="text-sm font-bold text-black "
              body={handleProduct}
            />
            <Column
              header={
                <div className="flex flex-col">
                  <p>UOM</p>
                  <p>وحدة قياس</p>
                </div>
              }
              className=" item-name font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={handleProductUOM}
            />
            {/* <Column
            header="Varient"
            className="w-5/12 item-name"
            headerClassName="text-sm"
            body={handleVarient}
          /> */}

            <Column
              header={
                <div className="flex flex-col">
                  <p>Unit Price</p>
                  <p>سعر الوحدة</p>
                </div>
              }
              className=" item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={handleProductUnitPrice}
            />
            <Column
              header={
                <div className="flex flex-col">
                  <p>Quantity</p>
                  <p>كمية</p>
                </div>
              }
              className=" item-price font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
              body={(rowData) => rowData?.quantity}
            />
            <Column
              body={(rowData) => rowData?.sale_price?.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <p>Net Amt Excl. VAT</p>
                  <p>صافي المبلغ باستثناء. ضريبة القيمة المضافة</p>
                </div>
              }
              cla
              className=" item-total font-bold text-black text-right"
              headerClassName="text-sm font-bold text-black"
            />
            <Column
              body={(rowData) => rowData.discount.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <p>Discount Rebate</p>
                  <p>خصم الخصم</p>
                </div>
              }
              className=" item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold text-black"
            />
            <Column
              body={(rowData) => rowData.total.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <p>VAT %</p>
                  <p>نسبة ضريبة القيمة المضافة</p>
                </div>
              }
              className=" item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold text-black"
            />
            <Column
              body={(rowData) => rowData.vat_amount.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <p>VAT Amnt</p>
                  <p>مبلغ ضريبة القيمة المضافة</p>
                </div>
              }
              className="item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold text-black"
            />
            <Column
              body={(rowData) => rowData.total.toLocaleString("en-IN")}
              header={
                <div className="flex flex-col">
                  <p>Amnt Incl.Vat</p>
                  <p>المبلغ شامل ضريبة القيمة المضافة</p>
                </div>
              }
              className=" item-total font-bold text-black text-right "
              headerClassName="text-sm font-bold text-black"
            />
          </DataTable>
        </div>
      </div>
      <br></br> <br></br> <br></br> <br></br>
      <div className="footer ">
        <div className=" flex flex-wrap  mt-4 mx-4">
          <div className=" fle flex-col">
            {toWords.convert("27421.75", "en-AE")}{" "}
            <p className=" text-right"> {stringText}</p>{" "}
          </div>
        </div>
        <div className=" flex justify-end mt-4 mx-4 ">
          <table className="table-auto    w-[48%] font-bold  ">
            <tbody>
              {totalAmount.map((item, index) => (
                <tr key={index} className="bg-white border-gray-200 h-full">
                  <td className="px-1 h-full ">{item.name}</td>
                  <td className="px-1  text-base h-full">{item.value}</td>
                  <td className="px-1  text-base text-right h-full">
                    {item.arabic}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
