import { Divider } from "primereact/divider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

export default function PurchaseCalculation({ formik }) {
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);

  const { t } = useTranslation();

  const calculateTotalSalePrice = () => {
    return formik?.values?.products?.reduce((accumulator, currentItem) => {
      console.log(currentItem.salePrice, currentItem.quantity);
      return accumulator + currentItem.salePrice * currentItem.quantity;
    }, 0);
  };
  const calculateTotalPrice = () => {
    return formik?.values?.products?.reduce((accumulator, currentItem) => {
      console.log(currentItem.total, currentItem.quantity);
      return accumulator + currentItem.total;
    }, 0);
  };
  const calculateTotalPurchasePrice = () => {
    return formik?.values?.products?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.purchasePrice,
      0
    );
  };
  const calculateTotalDiscount = () => {
    return formik?.values?.products?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.discount,
      0
    );
  };

  useEffect(() => {
    if (formik.values?.products.length > 0) {
      formik.setFieldValue("totalPurchase", calculateTotalPurchasePrice() || 0);
      formik.setFieldValue(
        "totalSales",
        formik.values.saleStatus === "refund"
          ? calculateTotalSalePrice() * -1
          : Math.abs(calculateTotalSalePrice()) || 0
      );
      formik.setFieldValue(
        "totalDiscount",
        formik.values.saleStatus === "refund"
          ? calculateTotalDiscount() * -1
          : Math.abs(calculateTotalDiscount()) || 0
      );
      formik.setFieldValue(
        "vatAmount",
        calculateTotalSalePrice() * (formik.values?.vatPer / 100) || 0
      );
      formik.setFieldValue(
        "totalAmount",
        formik.values.saleStatus === "refund"
          ? calculateTotalPrice() +
              calculateTotalPrice() * (formik.values?.vatPer / 100) * -1
          : Math.abs(
              calculateTotalPrice() +
                calculateTotalPrice() * (formik.values?.vatPer / 100)
            ) || 0
      );
    }
  }, [formik.values?.products, formik.values?.product]);

  return (
    <>
      <div className="w-full lg:w-7/12 xl:w-5/12">
        <div className="card px-4 pt-0 pb-4 shadow-md rounded-lg flex flex-col gap-2">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {formik.values?.saleStatus === "invoice"
                ? t("purchase_summary")
                : t("refund_summary")}
            </span>
          </Divider>
          <div className="flex justify-between ">
            <h1 className="text-lg font-semibold">{t("total_items")}</h1>
            <span className="text-lg">{formik?.values?.products?.length}</span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">
              {" "}
              {formik.values?.saleStatus === "invoice"
                ? t("purchase")
                : t("refund")}{" "}
              Price
            </h1>
            <span className="text-lg">
              {`${enableCurrency?.unit || ""} `}{" "}
              {formik.values?.totalSales.toLocaleString("en-IN")}
            </span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">{t("discount")}</h1>
            <span className="text-lg">
              {`${enableCurrency?.unit || ""} `}{" "}
              {formik.values?.totalDiscount.toLocaleString("en-IN")}
            </span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">{t("vat")} %</h1>
            <span className="text-lg">{formik.values?.vatPer}%</span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">{t("vat_amount")}</h1>
            <span className="text-lg">
              {`${enableCurrency?.unit || ""} `}{" "}
              {formik.values?.vatAmount.toLocaleString("en-IN")}
            </span>
          </div>

          <Divider
            pt={{
              root: { className: "!my-2" },
            }}
          />

          <div className="flex justify-end gap-3">
            <h1 className="text-lg font-semibold">{t("total_amount")}:</h1>
            <span className="text-lg">
              {`${enableCurrency?.unit || ""} `}{" "}
              {formik.values?.totalAmount.toLocaleString("en-IN")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
