import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";

import {
  deleteCategory,
  getCategory,
  getSubCategory,
  getspecificCategory,
} from "../../store/AsyncMethods/CategoryMethod";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Tag } from "primereact/tag";
import {
  deleteMultipleProduct,
  getProducts,
} from "../../store/AsyncMethods/ProductMethod";
import {
  deleteMultipleSales,
  getSales,
} from "../../store/AsyncMethods/SalesMethod";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import SaleInvoiceMain from "../Invoice/SaleInvoice/SaleInvoiceMain";

export default function SalesTable() {
  const { allSales } = useSelector((state) => state.SalesReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const { t } = useTranslation();

  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);

  const menu = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDelDialog, setShowDelDialog] = useState(false);
  const [showMultipleDelDialog, setShowMultipleDelDialog] = useState(false);
  const [selectedSales, setSelectedSales] = useState(null);
  const accept = () => {
    dispatch(deleteCategory(selectedItem?.id)).then((success) => {
      if (success) {
        setSelectedItem(null);
        dispatch(getCategory());
      }
    });
  };

  const reject = () => {
    setShowDelDialog(false);
  };

  const acceptMultiple = () => {
    if (selectedSales) {
      const idsArray = selectedSales.map((obj) => obj.id);

      if (selectedSales.length !== 0) {
        dispatch(deleteMultipleSales(idsArray)).then((success) => {
          if (success) {
            setSelectedSales(null);
            dispatch(getSales());
          }
        });
      }
    } else {
      toast.info("No any record selected.");
    }
  };

  const rejectMultiple = () => {
    setShowMultipleDelDialog(false);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const paginatorLeft = (
    <div className="mr-auto">
      <Button
        label={t("delete")}
        icon="pi pi-trash"
        className="p-red-btn !mr-auto"
        onClick={() => setShowMultipleDelDialog(true)}
      />
    </div>
  );

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="flex justify-between">
        {/* <div>
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-red-btn"
            onClick={() => setShowMultipleDelDialog(true)}
          />
        </div> */}
        <span className="p-input-icon-left flex w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12 ">
          {/* <i className="pi pi-search" /> */}
          <InputText
            className="w-full p-primary-input"
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  let items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        navigate(
          user?.role_id == 1
            ? `/edit-categories/${selectedItem?.id}`
            : `/edit-categories-sales/${selectedItem?.id}`
        );
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setShowDelDialog(true);
      },
    },
  ];

  const handleDownloadPdf = (rowData) => {
    navigate(
      user?.role_id == 1
        ? "/invoice-sales/" + rowData.id + "/:1"
        : "/invoice-sales-sales/" + rowData.id,
      { params: { isPdfDownload: true } }
    );
  };

  const actionBtn = (rowData) => (
    <React.Fragment>
      <div className="flex gap-2">
        <Button
          label={t("print")}
          icon="pi pi-print"
          className="p-black-btn"
          size="small"
          onClick={() =>
            navigate(
              user?.role_id == 1
                ? "/invoice-sales/" + rowData.id
                : "/invoice-sales-sales/" + rowData.id
            )
          }
        />

        <Button
          label={t("download_PDF")}
          icon="pi pi-download"
          className="p-black-btn"
          size="small"
          onClick={() => handleDownloadPdf(rowData)}
        />
      </div>
    </React.Fragment>
  );

  const totalPurchaseBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_purchase * enableCurrency?.rates
            : rowData.total_purchase / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_purchase?.toLocaleString("en-IN")
    }`;
  };

  const totalSalesBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_sales * enableCurrency?.rates
            : rowData.total_sales / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_sales?.toLocaleString("en-IN")
    }`;
  };

  const totalDiscountBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_discount * enableCurrency?.rates
            : rowData.total_discount / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_discount?.toLocaleString("en-IN")
    }`;
  };

  const totalVATBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.vat_amount * enableCurrency?.rates
            : rowData.vat_amount / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.vat_amount?.toLocaleString("en-IN")
    }`;
  };

  const totalAmountBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_amount * enableCurrency?.rates
            : rowData.total_amount / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_amount?.toLocaleString("en-IN")
    }`;
  };

  const productBody = (rowData) => {
    return `${rowData?.products?.length}`;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.is_active ? t("active") : t("deactive")}
        pt={{
          root: {
            className: getSeverity(rowData),
          },
        }}
      />
    );
  };

  const saleStatusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status === "refund" ? t("refund") : t("invoice")}
        pt={{
          root: {
            className: getSaleSeverity(rowData),
          },
        }}
      />
    );
  };

  const getSeverity = (data) => {
    switch (data.is_active) {
      case 1:
        return "bg-green";

      case 0:
        return "bg-red";

      default:
        return null;
    }
  };

  const getSaleSeverity = (data) => {
    switch (data.status) {
      case "refund":
        return "bg-red";

      case "invoice":
        return "bg-green";

      default:
        return null;
    }
  };

  const dateAndTimeBody = (rowData) => {
    const date = new Date(rowData.created_at);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    let hour = date.getHours();
    const minute = String(date.getMinutes()).padStart(2, "0");
    //const second = String(date.getSeconds()).padStart(2, '0');
    let period = "AM";

    if (hour >= 12) {
      period = "PM";
      hour = hour === 12 ? hour : hour - 12;
    }

    hour = String(hour).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}, ${hour}:${minute} ${period}`;
    return formattedDate;
  };

  return (
    <div>
      <div className="card shadow-sm">
        <DataTable
          value={allSales}
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          className=""
          header={header}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          selection={selectedSales}
          onSelectionChange={(e) => setSelectedSales(e.value)}
          pt={{
            filterOperator: { className: "w-3" },
            footerRow: { className: "!bg-red-900" },
          }}
          onRowClick={(e) =>
            navigate(
              user?.role_id == 1
                ? "/edit-sales/" + e.data.id
                : "/edit-sales-sales/" + e.data.id
            )
          }
          paginatorLeft={paginatorLeft}
          paginatorClassName=""
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            field="id"
            sortable
            header={t("invoice_hash")}
            style={{ minWidth: "130px" }}
            body={(rowData) => String(rowData?.id).padStart(5, "0")}
          />
          <Column
            field="customer_name"
            sortable
            header={t("customer_name")}
            style={{ minWidth: "180px" }}
          />
          <Column
            field="customer_phone"
            sortable
            header={t("customer_phone")}
            style={{ minWidth: "180px" }}
            className="text-center"
          />
          {/* <Column
            field="total_purchase"
            sortable
            header="Total Purchase"
            body={totalPurchaseBody}
            style={{ minWidth: "150px" }}
          /> */}

          <Column
            field="total_sales"
            sortable
            header={t("total_sales")}
            body={totalSalesBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />
          <Column
            field="total_discount"
            sortable
            header={t("total_discount")}
            body={totalDiscountBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />
          {/* <Column
            field="vat_amount"
            sortable
            header="VAT Amount"
            body={totalVATBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          /> */}
          <Column
            sortable
            field="total_amount"
            header={t("total_amount")}
            body={totalAmountBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />

          <Column
            header={t("total_products")}
            body={productBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />
          {/* <Column
            field="created_by_username"
            sortable
            header="Created By"
            style={{ minWidth: "130px" }}
          />
          <Column
            field="updated_by_username"
            sortable
            header="Updated By"
            style={{ minWidth: "130px" }}
          /> */}
          <Column
            header={t("action")}
            body={actionBtn}
            style={{ minWidth: "230px" }}
          />

          <Column
            field="status"
            sortable
            header={t("sale_status")}
            body={saleStatusBodyTemplate}
            style={{ minWidth: "130px" }}
          />

          <Column
            field="is_active"
            sortable
            header={t("status")}
            body={statusBodyTemplate}
            style={{ minWidth: "110px" }}
          />

          <Column
            field="created_at"
            sortable
            header={t("date")}
            body={dateAndTimeBody}
            style={{ minWidth: "170px" }}
          />
        </DataTable>
      </div>
      <ConfirmDialog
        message="Do you want to delete this record?"
        header={t("delete_confirmation")}
        icon="pi pi-info-circle"
        accept={accept}
        reject={reject}
        acceptLabel="Yes"
        visible={showDelDialog}
        onHide={() => setShowDelDialog(false)}
        pt={{
          acceptButton: {
            className: "p-red-btn",
          },
          rejectButton: {
            className: "p-primary-btn",
          },
          root: {
            className: "w-9/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12",
          },
          icon: {
            className: "mx-2",
          },
        }}
      />

      <ConfirmDialog
        message="Do you want to delete this record?"
        header={t("delete_confirmation")}
        icon="pi pi-info-circle"
        accept={acceptMultiple}
        reject={rejectMultiple}
        acceptLabel="Yes"
        visible={showMultipleDelDialog}
        onHide={() => setShowMultipleDelDialog(false)}
        pt={{
          acceptButton: {
            className: "p-red-btn",
          },
          rejectButton: {
            className: "p-primary-btn",
          },
          root: {
            className: "w-9/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12",
          },
          icon: {
            className: "mx-2",
          },
        }}
      />
    </div>
  );
}
