import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";

import { PrimeIcons } from "primereact/api";
import { useTranslation } from "react-i18next";
import { getCustomers } from "../../store/AsyncMethods/CustomerMethod";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getSalesReport } from "../../store/AsyncMethods/SalesMethod";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const SalesReportTable = ({ values }) => {
  const { user } = useSelector((state) => state.AuthReducer);
  // const values = location.state.values;
  const dt = useRef(null);
  const { allSalesReport } = useSelector((state) => state.SalesReducer);
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  //  console.log("values", values);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("sales-report-result"),
    // onBeforePrint: () => {
    //   window.close();
    // },
    onAfterPrint: () => {
      navigate("/manage-sales-report");
    },
    // onBeforePrint: () => navigate(-1),
  });

  // const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allCustomers } = useSelector((state) => state.CustomersReducer);
  const totalPurchaseBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_purchase * enableCurrency?.rates
            : rowData.total_purchase / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_purchase?.toLocaleString("en-IN")
    }`;
  };

  const totalSalesBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_sales * enableCurrency?.rates
            : rowData.total_sales / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_sales?.toLocaleString("en-IN")
    }`;
  };

  const totalDiscountBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_discount * enableCurrency?.rates
            : rowData.total_discount / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_discount?.toLocaleString("en-IN")
    }`;
  };

  const totalVATBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.vat_amount * enableCurrency?.rates
            : rowData.vat_amount / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.vat_amount?.toLocaleString("en-IN")
    }`;
  };

  const totalAmountBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${
      enableCurrency
        ? (enableCurrency?.calculate_method === "*"
            ? rowData.total_amount * enableCurrency?.rates
            : rowData.total_amount / enableCurrency?.rates
          ).toLocaleString("en-IN")
        : rowData?.total_amount?.toLocaleString("en-IN")
    }`;
  };
  // const handleExport = () => {
  //   console.log("dt.curremt",dt.current)
  //   dt.current.exportCSV();
  // };
  function arrayToCsv(arr) {
    const headers = Object.keys(arr[0]).slice(0, 9);
    const csvRows = [];

    arr.forEach((obj) => {
      const row = [];
      headers.forEach((header) => {
        row.push(obj[header]);
      });
      csvRows.push(row.join(","));
    });

    return `${headers.join(",")}\n${csvRows.join("\n")}`;
  }
  function handleExport(arr) {
    const csvString = arrayToCsv(arr);
    const blob = new Blob([csvString], { type: "text/csv; charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "sales_report.csv";
    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 0);
  }

  useEffect(() => {
    dispatch(getCustomers());
  }, []);
  useEffect(() => {
    dispatch(getSalesReport(values));
  }, [values]);
  const css = `
  .my-data-table-col {
      background-color: black !important;
      color: white !important;
  }

  
`;

  return (
    <div id="sales-report-result" className=" mx-11 mt-4">
      <div className="flex justify-between  mb-4">
        <div className="flex space-x-4">
          <div className=" h-full flex flex-col font-weight-800 overflow-auto max-w-xs whitespace-pre break-words">
            <span className=" font-bold text-gray-500 ">
              {" "}
              {user.first_name.toUpperCase()}
            </span>

            <span>
              {" "}
              {""}
              {user.email}
            </span>

            <span> {user.id}</span>
          </div>
        </div>
        <div>
          <h1 className="text-4xl font-bold text-center  mb-4">
            {t("manage_sale_report")}
          </h1>
          <div className="w-full mt-1 scale-75 leading-tight text-lg">
            <div className="text-center font-bold">
              Near Khawajgan Trust, Khawajgan Road, Gujrat
            </div>
            <div className="text-center font-bold">
              help@reyssolutions.com | 0322-5991588
            </div>
          </div>
        </div>
        <div className="flex h-full items-center justify-end gap-8  space-x-4 ">
          <div>
            <span className="text-gray-500 font-medium">FROM:</span>
            <span>{values.from_date}</span>
          </div>
          <div>
            <span className="text-gray-500 font-medium">TO:</span>
            <span>{values.to_date}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-end  mb-7">
        <Button
          icon="pi pi-envelope"
          severity="secondary"
          iconPos="left"
          label={t("email")}
          className="p-sales-report-btn  p-button-black p-button-rounded"
        />
        <Button
          icon="pi pi-file-excel"
          severity="secondary"
          iconPos="left"
          label="Excel"
          onClick={() => handleExport(allSalesReport)}
          className="p-sales-report-btn  p-button-black p-button-rounded "
        />
        <Button
          icon="pi pi-file-pdf"
          severity="secondary"
          onClick={handlePrint}
          iconPos="left"
          label="PDF"
          className="p-sales-report-btn  p-button-black p-button-rounded "
        />

        <Button
          clas=" bg-black"
          icon="pi pi-print"
          iconPos="left"
          onClick={handlePrint}
          severity="secondary"
          label="Print"
          className=" p-sales-report-btn  p-button-black p-button-rounded "
        />
      </div>
      <div className="card  shadow-sm mt-16">
        <DataTable
          id="sales-report-table"
          ref={dt}
          value={allSalesReport}
          scrollable
          scrollHeight="400px"
          style={{ minWidth: "50rem" }}
        >
          <Column
            headerClassName=" my-data-table-col"
            field="id"
            header={<p>Invoice No.</p>}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="name"
            header="Customer Name"
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            body={(row) => moment(row.created_at).format("YYYY-MM-DD")}
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">Total</p>
                <p>Transaction Date </p>
              </div>
            )}
          ></Column>

          <Column
            headerClassName=" my-data-table-col"
            field="total_sales"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allSalesReport.reduce(
                    (acc, cur) => acc + cur.total_sales,
                    0,
                  )}
                </p>
                <p>Sub Total </p>
              </div>
            )}
            body={totalSalesBody}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="vat_amount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allSalesReport.reduce((acc, cur) => acc + cur.vat_amount, 0)}
                </p>
                <p>VAT </p>
              </div>
            )}
            body={totalVATBody}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="total_amount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allSalesReport.reduce(
                    (acc, cur) => acc + cur.total_amount,
                    0,
                  )}
                </p>
                <p>Net Amount </p>
              </div>
            )}
            body={totalAmountBody}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="total_amount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allSalesReport.reduce(
                    (acc, cur) => acc + cur.total_amount,
                    0,
                  )}
                </p>
                <p>Total Amount </p>
              </div>
            )}
            body={totalAmountBody}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="total_sales"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allSalesReport.reduce(
                    (acc, cur) => acc + cur.total_sales,
                    0,
                  )}
                </p>
                <p>Total Cost </p>
              </div>
            )}
            body={totalSalesBody}
          ></Column>

          <Column
            headerClassName=" my-data-table-col"
            field="total_discount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allSalesReport.reduce(
                    (acc, cur) => acc + cur.total_discount,
                    0,
                  )}
                </p>
                <p>Discount </p>
              </div>
            )}
            body={totalDiscountBody}
          ></Column>
          {values.sales && (
            <Column
              headerClassName=" my-data-table-col"
              field="total_sales"
              header={() => (
                <div className=" ">
                  <p className=" fixed -mt-12 text-slate-950">
                    {allSalesReport.reduce(
                      (acc, cur) => acc + cur.total_sales,
                      0,
                    )}
                  </p>
                  <p>Sales </p>
                </div>
              )}
              body={totalSalesBody}
            ></Column>
          )}
          {values.profit && (
            <Column
              headerClassName=" my-data-table-col"
              field="total_amount"
              header={() => (
                <div className=" ">
                  <p className=" fixed -mt-12 text-slate-950">
                    {allSalesReport.reduce(
                      (acc, cur) => acc + cur.total_amount,
                      0,
                    )}
                  </p>
                  <p>Profit </p>
                </div>
              )}
              body={totalAmountBody}
            ></Column>
          )}
          {values.profitprcnt && (
            <Column
              headerClassName=" my-data-table-col"
              field="total_amount"
              header={() => (
                <div className=" ">
                  <p className=" fixed -mt-12 text-slate-950">
                    {allSalesReport.reduce(
                      (acc, cur) => acc + cur.total_amount,
                      0,
                    )}
                  </p>
                  <p>Profit % </p>
                </div>
              )}
              body={totalAmountBody}
            ></Column>
          )}

          {values.salesReturn && (
            <Column
              headerClassName=" my-data-table-col"
              field="total_amount"
              header={() => (
                <div className=" ">
                  <p className=" fixed -mt-12 text-slate-950">
                    {allSalesReport.reduce(
                      (acc, cur) => acc + cur.total_amount,
                      0,
                    )}
                  </p>
                  <p>Sales Return </p>
                </div>
              )}
              body={totalAmountBody}
            ></Column>
          )}
        </DataTable>
        <style>{css}</style>
      </div>
    </div>
  );
};

export default SalesReportTable;
