import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";

export default function AddSalesProductTable({
  products,
  removeProduct,
  editProduct,
  isDisabled,
}) {
  const menu = useRef(null);
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const { allVarients, products: allProducts } = useSelector(
    (state) => state.ProductReducer
  );
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  const [showDelDialog, setShowDelDialog] = useState(false);

  const accept = () => {
    removeProduct(selectedItem?.id);
  };

  const reject = () => {
    setShowDelDialog(false);
  };

  let items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        editProduct(selectedItem);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setShowDelDialog(true);
      },
    },
  ];

  const actionBtn = (rowData) => (
    <React.Fragment>
      <div className=" ">
        <i
          className="pi pi-cog text-secondary cursor-pointer"
          onClick={(e) => {
            menu.current.toggle(e);
            setSelectedItem(rowData);
          }}
        ></i>
        <Menu model={items} popup ref={menu} className="p-0 z-50" />
      </div>
    </React.Fragment>
  );

  const salePriceBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${rowData.salePrice?.toLocaleString(
      "en-IN"
    )}`;
  };

  const purchasePricePriceBody = (rowData) => {
    return `${
      enableCurrency?.unit || ""
    } ${rowData.purchasePrice?.toLocaleString("en-IN")}`;
  };

  const discountBody = (rowData) => {
    return rowData.is_discount_percentage
      ? `${rowData.discount_value}%`
      : `${enableCurrency?.unit || ""} ${rowData.discount_value?.toLocaleString(
          "en-IN"
        )}`;
  };

  const discountAmountBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${rowData.discount?.toLocaleString(
      "en-IN"
    )}`;
  };
  const totalBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${rowData.total?.toLocaleString(
      "en-IN"
    )}`;
  };
  const vatPerBody = (rowData) => {
    return ` ${rowData.vat_per?.toLocaleString("en-IN")}%`;
  };

  const vatAmountBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${rowData.vat_amount?.toLocaleString(
      "en-IN"
    )}`;
  };

  const vatTotalBody = (rowData) => {
    return `${enableCurrency?.unit || ""} ${rowData.total_amount_vat?.toLocaleString(
      "en-IN"
    )}`;
  };

  const productBody = (rowData) => {
    const product = allProducts.find(
      (product) => product.id === rowData.product
    );
    return `${product?.title} - ${product?.article_name}`;
  };

  const varientBody = (rowData) => {
    const varient = allVarients.find(
      (varient) => varient.varient_id === rowData.varient
    );

    if (varient) {
      const parsedOptions = JSON.parse(varient?.options);

      const optionLabel = parsedOptions
        .map((option) => {
          return `${option} - ${varient[option]?.value || "N/A"}`;
        })
        .join("  |  ");
      return `${optionLabel}`;
    }
    return "";
  };

  return (
    <div>
      <div className="card shadow-sm">
        <DataTable
          value={products}
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          pt={{
            filterOperator: { className: "w-3" },
            footerRow: { className: "!bg-red-900" },
          }}
          size="small"
        >
          <Column field="id" header={t("id")} />

          <Column field="product" header={t("product")} body={productBody} />
          {/* <Column field="varient" header="Varient" body={varientBody} /> */}
          <Column field="quantity" header={t("quantity")} />
          {/* <Column
            field="purchasePrice"
            header="Purchase Price"
            body={purchasePricePriceBody}
          /> */}
          <Column
            field="salePrice"
            header={t("sale_price")}
            body={salePriceBody}
          />
          <Column field="discount" header={t("discount")} body={discountBody} />
          <Column
            field="discount_value"
            header={t("discount_amount")}
            body={discountAmountBody}
          />
          <Column field="total" header={t("total")} body={totalBody} />
          <Column field="vat_per" header={t("Vat %")} body={vatPerBody} />
          <Column
            field="vat_amount"
            header={t("Vat Amount")}
            body={vatAmountBody}
          />
          <Column
            field="total_amount_vat"
            header={t("Total (incl Vat)")}
            body={vatTotalBody}
          />
          {!isDisabled && <Column header={t("action")} body={actionBtn} />}
        </DataTable>
      </div>

      <ConfirmDialog
        message="Do you want to delete this record?"
        header="Delete confirmation"
        icon="pi pi-info-circle"
        accept={accept}
        reject={reject}
        acceptLabel="Yes"
        visible={showDelDialog}
        onHide={() => setShowDelDialog(false)}
        pt={{
          acceptButton: {
            className: "p-red-btn",
          },
          rejectButton: {
            className: "p-primary-btn",
          },
          root: {
            className: "w-9/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12",
          },
          icon: {
            className: "mx-2",
          },
        }}
      />
    </div>
  );
}
