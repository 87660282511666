import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_ERROR, RESET_SUCCESS } from "../../store/Types/AuthTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PurchaseTable from "./PurchaseTable";
import { getPurchase } from "../../store/AsyncMethods/PurchaseMethod";
import { getProducts } from "../../store/AsyncMethods/ProductMethod";
import { getSubCategory } from "../../store/AsyncMethods/CategoryMethod";

export default function PurchaseMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { success, error, user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch({ type: RESET_SUCCESS });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: RESET_ERROR });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      dispatch(getSubCategory());
      dispatch(getProducts());
      dispatch(getPurchase());
    }
  }, []);

  return (
    <div className="">
      <div className="flex justify-end">
        <Button
          label={t("add_purchase")}
          icon="pi pi-plus"
          className="p-primary-btn"
          onClick={() => navigate("/add-purchase")}
        />
      </div>
      <div className="my-4">
        <PurchaseTable />
      </div>
    </div>
  );
}
