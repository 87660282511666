import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";

import { useTranslation } from "react-i18next";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getPurchaseReport } from "../../store/AsyncMethods/PurchaseMethod";
import { getProductReport } from "../../store/AsyncMethods/ProductMethod";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const ProductReportTable = ({ values }) => {
  const { user } = useSelector((state) => state.AuthReducer);
  // const values = location.state.values;
  const { allProductReport } = useSelector((state) => state.ProductReducer);
  //  console.log("values", values);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("item-report-result"),
    // onBeforePrint: () => {
    //   window.close();
    // },
    onAfterPrint: () => {
      navigate("/manage-item-report");
    },
    // onBeforePrint: () => navigate(-1),
  });

  // const { specificSale } = useSelector((state) => state.SalesReducer);
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);

  // const handleExport = () => {
  //   dt.current.exportCSV();
  // };
  function arrayToCsv(arr) {
    const headers = Object.keys(arr[0]).slice(0, 9);
    const csvRows = [];

    arr.forEach((obj) => {
      const row = [];
      headers.forEach((header) => {
        row.push(obj[header]);
      });
      csvRows.push(row.join(","));
    });

    return `${headers.join(",")}\n${csvRows.join("\n")}`;
  }
  let initialAllProductQuantity = {
    total: "Total",
    quantity: 0,
    quantitySold: 0,
    quantitySalesReturn: 0,
    quantityPurchaseReturn: 0,
    quantityPurchase: 0,
  };
  const [allProductQuantity, setAllProductQuantity] = useState([
    initialAllProductQuantity,
  ]);

  function handleExport(arr) {
    const csvString = arrayToCsv(arr);
    const blob = new Blob([csvString], { type: "text/csv; charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "purchase_report.csv";
    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 0);
  }

  useEffect(() => {
    dispatch(getProductReport(values));
  }, [values]);
  const css = `
  .my-data-table-col {
      background-color: black !important;
      color: white !important;
  }
  .my-data-table thead {
    visibility: hidden;
    border: 1px solid white !important;
    
}

.my-data-table{
  font-weight: bold ;
  position: relative;
}

`;
  useEffect(() => {
    if (allProductReport) {
      for (const product of allProductReport) {
        initialAllProductQuantity.quantity += product.quantity;
        initialAllProductQuantity.quantitySold += product.quantity_sold;
        initialAllProductQuantity.quantitySalesReturn +=
          product.quantity_sales_return;
        initialAllProductQuantity.quantityPurchaseReturn +=
          product.quantity_purchase_return;
        initialAllProductQuantity.quantityPurchase += product.quantity_purchase;

        setAllProductQuantity([initialAllProductQuantity]);
      }
    }
  }, [allProductReport]);

  return (
    <div id="item-report-result" className=" mx-11 mt-4">
      <div className="flex justify-between  mb-4">
        <div className="flex space-x-4">
          <div className=" h-full flex flex-col font-weight-800 overflow-auto max-w-xs whitespace-pre break-words">
            <span className=" font-bold text-gray-500 ">
              {" "}
              {user.first_name.toUpperCase()}
            </span>

            <span>
              {" "}
              {""}
              {user.email}
            </span>

            <span> {user.id}</span>
          </div>
        </div>
        <div>
          <h1 className="text-4xl font-bold text-center  mb-4">
            {t("manage_item_report").toUpperCase()}
          </h1>
          <div className="w-full mt-1 scale-75 leading-tight text-lg">
            <div className="text-center font-bold">
              Near Khawajgan Trust, Khawajgan Road, Gujrat
            </div>
            <div className="text-center font-bold">
              help@reyssolutions.com | 0322-5991588
            </div>
          </div>
        </div>
        <div className="flex h-full items-center justify-end gap-8  space-x-4 ">
          <div>
            <span className="text-gray-500 font-medium">FROM:</span>
            <span>{values.from_date}</span>
          </div>
          <div>
            <span className="text-gray-500 font-medium">TO:</span>
            <span>{values.to_date}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-end  mb-7">
        <Button
          icon="pi pi-envelope"
          severity="secondary"
          iconPos="left"
          label={t("email")}
          className="p-sales-report-btn  p-button-black p-button-rounded"
        />
        <Button
          icon="pi pi-file-excel"
          severity="secondary"
          iconPos="left"
          label="Excel"
          onClick={() => handleExport(allProductReport)}
          className="p-sales-report-btn  p-button-black p-button-rounded "
        />
        <Button
          icon="pi pi-file-pdf"
          severity="secondary"
          onClick={handlePrint}
          iconPos="left"
          label="PDF"
          className="p-sales-report-btn  p-button-black p-button-rounded "
        />

        <Button
          clas=" bg-black"
          icon="pi pi-print"
          iconPos="left"
          onClick={handlePrint}
          severity="secondary"
          label="Print"
          className=" p-sales-report-btn  p-button-black p-button-rounded "
        />
      </div>
      <div className="card  shadow-sm mt-16">
        <DataTable
          id="item-total-report-table"
          value={allProductQuantity}
          className=" my-data-table"
          style={{ minWidth: "50rem" }}
        >
          <Column
            // headerClassName=" my-data-table-col"

            header={<p>S.No</p>}
          ></Column>
          <Column
            // headerClassName=" my-data-table-col"

            header={<p>Item Number</p>}
          ></Column>
          <Column
            //headerClassName=" my-data-table-col"
            header="Item Description"
            field="total"
          ></Column>
          <Column
            //headerClassName=" my-data-table-col"
            field="quantity"
            header="Quantity on Hand"
          ></Column>
          <Column
            //headerClassName=" my-data-table-col"
            field="quantitySold"
            header="Quantity Sold"
          ></Column>
          <Column
            // headerClassName=" my-data-table-col"
            field="quantitySalesReturn"
            header="Quantity Sales Return"
          ></Column>
          <Column
            // headerClassName=" my-data-table-col"
            field="quantityPurchase"
            header="Quantity Purchase"
          ></Column>
          <Column
            //headerClassName=" my-data-table-col"
            field="quantityPurchaseReturn"
            header="Quantity Purchase Return"
          ></Column>
        </DataTable>
        <DataTable
          id="item-report-table"
          value={allProductReport}
          style={{ minWidth: "50rem" }}
        >
          <Column
            headerClassName=" my-data-table-col"
            field="id"
            header={<p>S.No</p>}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="article_name"
            header={<p>Item Number</p>}
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="title"
            header="Item Description"
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="quantity"
            header="Quantity on Hand"
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="quantity_sold"
            header="Quantity Sold"
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="quantity_sales_return"
            header="Quantity Sales Return"
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="quantity_purchase"
            header="Quantity Purchase"
          ></Column>
          <Column
            headerClassName=" my-data-table-col"
            field="quantity_purchase_return"
            header="Quantity Purchase Return"
          ></Column>
          {/* <Column
            headerClassName=" my-data-table-col"
            body={(row) => moment(row.created_at).format("YYYY-MM-DD")}
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">Total</p>
                <p>Transaction Date </p>
              </div>
            )}
          ></Column> */}

          {/* <Column
            headerClassName=" my-data-table-col"
            field="total_sales"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allPurchasesReport.reduce(
                    (acc, cur) => acc + cur.total_sales,
                    0,
                  )}
                </p>
                <p>Sub Total </p>
              </div>
            )}
            body={totalSalesBody}
          ></Column> */}
          {/* <Column
            headerClassName=" my-data-table-col"
            field="vat_amount"
            body={totalVATBody}
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allPurchasesReport.reduce(
                    (acc, cur) => acc + cur.vat_amount,
                    0,
                  )}
                </p>
                <p>VAT </p>
              </div>
            )}
          ></Column> */}
          {/* <Column
            headerClassName=" my-data-table-col"
            field="total_amount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allPurchasesReport.reduce(
                    (acc, cur) => acc + cur.total_amount,
                    0,
                  )}
                </p>
                <p>Net Amount </p>
              </div>
            )}
            body={totalAmountBody}
          ></Column> */}
          {/* <Column
            headerClassName=" my-data-table-col"
            field="total_amount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allPurchasesReport.reduce(
                    (acc, cur) => acc + cur.total_amount,
                    0,
                  )}
                </p>
                <p>Total Amount </p>
              </div>
            )}
            body={totalAmountBody}
          ></Column> */}
          {/* <Column
            headerClassName=" my-data-table-col"
            field="total_sales"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allPurchasesReport.reduce(
                    (acc, cur) => acc + cur.total_sales,
                    0,
                  )}
                </p>
                <p>Total Cost </p>
              </div>
            )}
            body={totalPurchaseBody}
          ></Column> */}

          {/* <Column
            headerClassName=" my-data-table-col"
            field="total_discount"
            header={() => (
              <div className=" ">
                <p className=" fixed -mt-12 text-slate-950">
                  {allPurchasesReport.reduce(
                    (acc, cur) => acc + cur.total_discount,
                    0,
                  )}
                </p>
                <p>Discount </p>
              </div>
            )}
            body={totalDiscountBody}
          ></Column> */}
        </DataTable>
        <style>{css}</style>
      </div>
    </div>
  );
};

export default ProductReportTable;
