import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getVat } from "../../store/AsyncMethods/SetupMethod";
import VatDetailTable from "./VatDetailTable";

const VatDetailMain = () => {
  const { allVat } = useSelector((state) => state.VatReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (user) {
      dispatch(getVat());
    }
  }, []);
  if (allVat) {
    //console.log("allVat", allVat);
  }
  return (
    <div className="mx-3">
      <div className="flex justify-end">
        <Button
          label={t("add_vat")}
          icon="pi pi-plus"
          className="p-primary-btn"
          onClick={() => navigate("/setup-add-vat-detail")}
        />
      </div>

      <div className="my-4">
        <VatDetailTable />
      </div>
    </div>
  );
};

export default VatDetailMain;
